import React from 'react';
import styled from '@emotion/styled';

import CardThreeColumnItem from './CardThreeColumnItem';
import { CardThreeColumnBlock } from '@mq/generated-types/dist/CardThreeColumnBlock';
import { CollapseRow } from '../styles/Library';

const Content = styled(CollapseRow)`
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: -32px;
  height: 100%;
`;
interface Props {
  list: CardThreeColumnBlock['cards'];
  isDarkTheme: boolean;
}
const CardThreeColumnList: React.FC<Props> = ({ list, isDarkTheme }: Props) => {
  if (!list) return null;
  return (
    <Content>
      {list.map((listItem) => {
        if (!listItem) return null;
        return (
          <CardThreeColumnItem
            key={String('title' in listItem ? listItem.title : '')}
            title={'title' in listItem ? listItem.title : ''}
            description={'description' in listItem ? listItem.description : ''}
            buttonLabel={'buttonLabel' in listItem ? listItem.buttonLabel : ''}
            buttonLink={'buttonLink' in listItem ? listItem.buttonLink : ''}
            isDarkTheme={isDarkTheme}
          />
        );
      })}
    </Content>
  );
};

export default CardThreeColumnList;
