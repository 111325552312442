/* eslint-disable max-len */
import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import { GetApp } from '@material-ui/icons';

import {
  CenteredFlex,
  PageComponentText,
  SectionTextDark,
  SectionSubTextDark,
} from '../../styles/Library';
import { respondTo } from '../../styles/mixins';
import { colors } from '../../styles/variables';

import PageComponent from '../PageComponent';

interface Props extends HTMLAttributes<HTMLDivElement> {
  // Breaking convention here to be able to reuse this component for downloads
  // in both product page and sales and marketing
  // see: ../generated/Downloads.d.ts
  // see: ../generated/SalesMarketingDownloads.d.ts
  // craft: DownloadsI;
  id: string;
  text: string | null;
  subText: string | null;
  // assets: (Asset | null)[] | null;
  assets: (any | null)[] | null;
}

const DownloadsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${respondTo.md`
    flex-direction: row;
    flex-wrap: wrap;
  `}
`;
const DownloadItem = styled(CenteredFlex)`
  flex-direction: column;
  border: 1px solid ${colors.brand.accent};
  margin: 8px 0px;
  width: 100%;
  background-color: ${colors.white};
  ${respondTo.md`
    width: 23.3%;
  `}
  ${respondTo.xl`
    width: 23.9%;
  `}
  overflow: hidden;
  :not(:nth-of-type(4n - 3)) {
    ${respondTo.md`
      margin-left: 16px;
    `}
  }
`;
const Filename = styled.div`
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  height: 100%;
  color: #0e171f;
  padding: 16px 16px 24px 16px;
`;

const CTAContainer = styled.a`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${colors.offwhite};
  color: ${colors.text.accent};
  font-size: 1rem;
  border-top: 1px solid ${colors.brand.accent};
`;
const CTAContentContainer = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: center;
`;
const DownloadTextContainer = styled(CTAContentContainer)``;
const FileExtension = styled.span`
  text-align: center;
`;
const DownloadIconContainer = styled(CTAContentContainer)`
  border-left: 1px solid ${colors.brand.accent};
  color: ${colors.blue};
  font-size: 1.275rem;
`;

const Files: React.FC<Props> = ({ id, text, subText, assets }) => (
  <div id={id}>
    <PageComponent backgroundColor={colors.offwhite}>
      {(text || subText) && (
        <PageComponentText>
          <SectionTextDark>{text}</SectionTextDark>
          <SectionSubTextDark>{subText}</SectionSubTextDark>
        </PageComponentText>
      )}
      <DownloadsContainer>
        {assets?.map((download) =>
          download ? (
            <DownloadItem key={download.id || ''}>
              <Filename>{download.title}</Filename>
              {download.url && (
                <CTAContainer target="_blank" href={download.url} download>
                  <DownloadTextContainer>
                    <FileExtension>
                      {download.extension?.toUpperCase()}
                    </FileExtension>
                  </DownloadTextContainer>
                  <DownloadIconContainer>
                    <GetApp fontSize="inherit" />
                  </DownloadIconContainer>
                </CTAContainer>
              )}
            </DownloadItem>
          ) : null,
        )}
      </DownloadsContainer>
    </PageComponent>
  </div>
);

export default Files;
