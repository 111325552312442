import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../../styles/variables';
import { respondTo } from '../../styles/mixins';
import PageComponent from '../PageComponent';
import { PostMetadata } from '../PostMetadata';

const Body = styled.div`
  background-color: ${colors.white};
  border-left: 1px solid ${colors.border.light};
  border-right: 1px solid ${colors.border.light};
  padding: 24px;
  ${respondTo.md`
    padding: 32px 54px;
  `}
  ${respondTo.xl`
    padding: 56px 198px;
  `}
  pre {
    background-color: ${colors.offwhite};
    padding: 16px;
  }
  blockquote {
    border: 1px solid ${colors.border.light};
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 0px;
    border-right-width: 0px;
    padding: 26px 0px;
  }
`;

interface Props {
  post: {
    id: string | null;
    title: string | null;
    description: string | null;
    editor: string | null;
    postDate: string | null;
    authors: {
      fullName: string | null;
    }[];
  };
}
export const PostBody = ({ post }: Props) => {
  const { authors, editor, postDate } = post;
  return (
    <PageComponent backgroundColor={colors.offwhite}>
      <Body dangerouslySetInnerHTML={{ __html: editor || '' }} />
      <PostMetadata authors={authors} postDate={postDate} />
    </PageComponent>
  );
};
