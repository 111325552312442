import React from 'react';
import styled from '@emotion/styled';

import PageComponent from '../PageComponent';
import TabbedHorizontalList from '../TabbedHorizontalList';
import {
  PageComponentText,
  SectionTextDark,
  ComponentSubText,
} from '../../styles/Library';
import { TabbedHorizontalBlock } from '@mq/generated-types/dist/TabbedHorizontalBlock';

const Content = styled.div``;

interface TabbedHorizontalI {
  craft: TabbedHorizontalBlock;
}

const TabbedHorizontal: React.FC<TabbedHorizontalI> = ({
  craft,
}: TabbedHorizontalI) => {
  const { blockTitle, description, tabs, transitionTabs, numberTabs } = craft;
  return (
    <PageComponent>
      <Content>
        {(blockTitle || description) && (
          <PageComponentText>
            <SectionTextDark>{blockTitle}</SectionTextDark>
            <ComponentSubText>{description}</ComponentSubText>
          </PageComponentText>
        )}
        <TabbedHorizontalList
          list={tabs}
          transitionTabs={transitionTabs}
          numberTabs={numberTabs}
        />
      </Content>
    </PageComponent>
  );
};

export default TabbedHorizontal;
