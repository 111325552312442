/* eslint-disable max-len */
import React from 'react';
import styled from '@emotion/styled';

import {
  IMG,
  CollapseRow,
  SectionTextDark,
  SectionSubTextDark,
} from '../../styles/Library';
import PageComponent from '../PageComponent';
import { respondTo } from '../../styles/mixins';
import { colors } from '../../styles/variables';
import { FeatureOverUnderBlock } from '@mq/generated-types/dist/FeatureOverUnderBlock';

interface CraftData {
  craft: FeatureOverUnderBlock;
}

const TextRow = styled(CollapseRow)`
  ${respondTo.md`
    align-items: flex-start;
  `}
`;
const TextContainer = styled.div`
  width: 100%;
  ${respondTo.md`
    width: 50%;
  `}
`;
const Padding = styled.div`
  width: 100%;
  height: 32px;
  ${respondTo.md`
    height: 56px;
  `}
  ${respondTo.xl`
    height: 72px;
  `}
`;
const BlockTitle = styled(SectionTextDark)`
  font-weight: 600;
  width: 100%;
  ${respondTo.md`
    padding-right: 145px;
  `}
  ${respondTo.xl`
    line-height: 68px;
    padding-right: 75px;
  `}
`;

const FeatureOverUnder: React.FC<CraftData> = ({ craft }: CraftData) => {
  const { blockTitle, description, image, theme } = craft;
  const isDarkTheme = () =>
    theme === 'gray' || theme === 'dark' || theme === 'grey';
  const hasText = blockTitle || description;
  const hasImage = image && image[0] && image[0].url;
  return (
    <PageComponent
      backgroundColor={isDarkTheme() ? colors.offwhite : colors.white}
    >
      {hasText && (
        <TextRow>
          <TextContainer>
            <BlockTitle className="full-width">{blockTitle}</BlockTitle>
          </TextContainer>
          <TextContainer>
            <SectionSubTextDark className="full-width">
              {description}
            </SectionSubTextDark>
          </TextContainer>
        </TextRow>
      )}
      {hasText && hasImage && <Padding />}
      {image && image[0] && image[0].url && (
        <IMG src={image[0].url || ''} alt={image[0].title || ''} />
      )}
    </PageComponent>
  );
};
//
export default FeatureOverUnder;
