import React from 'react';
import styled from '@emotion/styled';
import { CenteredFlex } from '../styles/Library';
import { colors } from '../styles/variables';
import { respondTo } from '../styles/mixins';

const Container = styled(CenteredFlex)`
  width: 100%;
  padding: 24px;
  min-height: 277px;
  border: 1px solid ${colors.brand.accent};
  ${respondTo.md`
    padding: 32px;
    width: 50%;
    min-height: 475px;
    &.left {
      border-right: none;
    }
    &.right {
      border-left: none;
    }
  `}
  ${respondTo.xl`
    padding: 104px;
    min-height: 656px;
  `}
  background-color: ${colors.white};
`;

interface InfoProps {
  className?: string;
}
const InfoBlock: React.FC<InfoProps> = ({ children, className }) => (
  <Container className={className}>{children}</Container>
);

export default InfoBlock;
