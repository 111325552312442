import React from 'react';
import styled from '@emotion/styled';

import CardTwoColumnItem from './CardTwoColumnItem';
import { CardTwoColumnBlock } from '@mq/generated-types/dist/CardTwoColumnBlock';
import { CollapseRow } from '../styles/Library';

const Content = styled(CollapseRow)`
  width: 100%;
  flex-wrap: wrap;
`;

interface CardTwoColumnProps {
  list: CardTwoColumnBlock['cards'];
}
const CardGallery: React.FC<CardTwoColumnProps> = ({
  list,
}: CardTwoColumnProps) => {
  if (!list) return null;
  return (
    <Content>
      {list.map((listItem) => {
        if (!listItem) return null;
        return (
          <CardTwoColumnItem
            key={String('title' in listItem ? listItem.title : '')}
            title={'title' in listItem ? listItem.title : ''}
            description={'description' in listItem ? listItem.description : ''}
            image={'image' in listItem ? listItem.image : null}
            buttonLink={'buttonLink' in listItem ? listItem.buttonLink : ''}
          />
        );
      })}
    </Content>
  );
};

export default CardGallery;
