import React from 'react';

import { Asset } from '@mq/generated-types/dist/Asset';

interface Props {
  asset: Asset;
}

// TODO: currently only images
const CraftAsset = ({ asset: { title, url } }: Props): JSX.Element | null =>
  title && url ? <img alt={title} src={url} /> : null;

export default CraftAsset;
