import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { ArrowForward } from '@material-ui/icons';
import { CardTwoColumnBlock_cards_Craft_cardTwoColumn_cardTwoColumn_Entry as CardI } from '@mq/generated-types/dist/CardTwoColumnBlock';

import { colors } from '../styles/variables';
import {
  CenteredFlex,
  IMG,
  SectionSubTextDark,
  SubComponentSubTextDark,
} from '../styles/Library';
import { respondTo } from '../styles/mixins';

const Card = styled(Link)`
  background-color: ${colors.offwhite};
  border: 1px solid ${colors.brand.accent};
  color: ${colors.text.dark};
  padding: 32px 32px 0px 32px;
  width: 100%;
  ${respondTo.md`
    width: 47.5%;
  `};
  ${respondTo.xl`
  width: 48.5%;
`};
  margin-top: 32px;
  &:hover {
    border: 1px solid ${colors.blue};
    text-decoration: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :nth-of-type(even) {
    ${respondTo.md`
      margin-left: 32px;
    `};
  }
`;

const CardTitle = styled(SectionSubTextDark)`
  color: ${colors.text.dark};
  font-weight: 600;
  padding-bottom: 8px;
`;
const CardSubTitle = styled(SubComponentSubTextDark)`
  padding-bottom: 16px;
  ${respondTo.md`
    padding-bottom: 8px;
  `}
`;
const HoverIconContainer = styled(CenteredFlex)`
  height: 48px;
  width: 48px;
  color: ${colors.text.light};
  background-color: ${colors.blue};
  visibility: hidden;
  &.hovered {
    visibility: visible;
  }
  margin-bottom: 32px;
  display: none;
  ${respondTo.md`
    display: inherit;
  `}
`;
const AssetContainer = styled.div`
  width: 100%;
`;
const StyledIMG = styled(IMG)`
  object-fit: fill;
`;

const CardTwoColumnItem: React.FC<CardI> = (item: CardI) => {
  const [isHovered, setIsHovered] = useState(false);
  const { image } = item;
  const currentImage = image ? image[0] : null;
  return (
    <Card
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      to={item.buttonLink || ''}
    >
      <div>
        <CardTitle>{item.title}</CardTitle>
        <CardSubTitle>{item.description}</CardSubTitle>
      </div>
      <HoverIconContainer className={isHovered ? 'hovered' : ''}>
        <ArrowForward fontSize="inherit" />
      </HoverIconContainer>
      {currentImage && (
        <AssetContainer>
          {currentImage.url && (
            <StyledIMG src={currentImage.url} alt={currentImage.title || ''} />
          )}
        </AssetContainer>
      )}
    </Card>
  );
};

export default CardTwoColumnItem;
