import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../styles/variables';

import Container from '../Container';
import { SectionTextLight, SectionSubTextLight } from '../../styles/Library';
import { respondTo } from '../../styles/mixins';
import { IntroSectionBlock } from '@mq/generated-types/dist/IntroSectionBlock';

import { createAnchorSlug } from '../../utils/misc';

const Background = styled.div`
  background-color: ${colors.blue};
  border-bottom: 1px solid ${colors.blueaccent};
`;
const StyledContainer = styled(Container)`
  width: 100%;
  border-left: 1px solid ${colors.blueaccent};
  border-right: 1px solid ${colors.blueaccent};
  display: flex;
  justify-content: center;
`;
const TopPadding = styled.div`
  background-color: ${colors.blueaccent};
  height: 24px;
`;
const LargeHeader = styled(SectionTextLight)`
  font-size: 3rem;
  line-height: 3.2rem;
  ${respondTo.xl`
    line-height: 4.2rem;
    font-size: 4rem;
  `}
`;
const LightSubText = styled(SectionSubTextLight)`
  color: #cfd8e0;
`;
const Content = styled.div`
  width: 90%;
  ${respondTo.md`
    width: 70%;
  `}
`;

interface IntroSectionI {
  craft: IntroSectionBlock;
}
const IntroSection: React.FC<IntroSectionI> = ({ craft }: IntroSectionI) => {
  // we use this as an anchor link at times
  // use the `blockTitle` (required in Craft!) as the `id`
  const { blockTitle, description } = craft;
  return blockTitle ? (
    <Background id={createAnchorSlug(blockTitle)}>
      <TopPadding />
      <StyledContainer>
        <Content>
          <LargeHeader className="full-width">{blockTitle}</LargeHeader>
          <LightSubText className="full-width">{description}</LightSubText>
        </Content>
      </StyledContainer>
    </Background>
  ) : null;
};

export default IntroSection;
