import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { ArrowForward } from '@material-ui/icons';

import AssetPlaceholder from '../AssetPlaceholder';
import PageComponent from '../PageComponent';
import { ComponentSubText } from '../../styles/Library';
import { colors } from '../../styles/variables';
import { respondTo } from '../../styles/mixins';
import { VideoBlock } from '@mq/generated-types/dist/VideoBlock';

const Title = styled.div`
  font-size: 1.2rem;
  line-height 2.4rem;
  font-weight: 600;
  padding-bottom: 10px;
  ${respondTo.xl`
    font-size: 1.38rem;
    line-height 1.7rem;
    padding-bottom: 16px;
  `}
`;
const Description = styled(ComponentSubText)`
  font-size 1rem !important;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  ${respondTo.md`
    flex-direction: row;
  `}
`;
const VideoContainer = styled.div`
  width: 100%;
  height: 225px;
  ${respondTo.md`
    height: auto;
    width: 60%;
  `}
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${colors.brand.accent};
  width: 100%;
  ${respondTo.md`
    width: 40%;
    border-left-width: 0;
  `}
`;
const Info = styled.div`
  width: 100%;
  padding: 32px;
`;
const CTAContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${colors.offwhite};
  border: 1px solid ${colors.brand.accent};
  padding: 16px 24px;
  ${respondTo.xl`
    padding: 24px 32px;
  `}
  color: ${colors.blue};
  font-weight: 600;
  font-size: 1rem;
  :hover {
    cursor: pointer;
    text-decoration: none;
    background-color: ${colors.gray.dark};
  }
`;
const VideoComponent = styled.video`
  object-fit: cover;
  :focus {
    outline: none;
  }
`;

interface CraftResponseI {
  craft: VideoBlock;
}

const VideoBlockComponent: React.FC<CraftResponseI> = ({
  craft,
}: CraftResponseI) => {
  const { blockTitle, description, icon, label, video, videoLink } = craft;
  return (
    <PageComponent>
      <StyledContainer>
        <VideoContainer>
          {video && video[0] && video[0].url ? (
            <VideoComponent
              height="100%"
              width="100%"
              controls
              controlsList="nodownload"
            >
              <source src={video[0].url} />
            </VideoComponent>
          ) : (
            <AssetPlaceholder />
          )}
        </VideoContainer>
        <InfoContainer>
          <Info>
            <Title className="full-width">{blockTitle}</Title>
            <Description>{description}</Description>
          </Info>
          {videoLink && label && (
            <CTAContainer to={videoLink}>
              <span>{label}</span>
              <span>
                <ArrowForward fontSize="inherit" />
              </span>
            </CTAContainer>
          )}
        </InfoContainer>
      </StyledContainer>
    </PageComponent>
  );
};

export default VideoBlockComponent;
