import { css } from '@emotion/core';

export const injectStyles = (size: number, styles: TemplateStringsArray) => css`
  @media (min-width: ${size}px) {
    ${css`
      ${css(...styles)}
    `};
  }
`;
export const getPixelVal = (size: string | undefined) => {
  if (size === 'xs') return 24;
  if (size === 'sm') return 32;
  if (size === 'md') return 40;
  if (size === 'lg') return 48;
  if (size === 'xl') return 56;
  return 32;
};
