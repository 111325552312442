import React from 'react';
import styled from '@emotion/styled';

import { FlexRow } from '../styles/Library';
import { colors } from '../styles/variables';
import { Input } from '@mq/aurora-components/dist/input';
import { Checkbox } from '@mq/aurora-components/dist/checkbox';

const InputContainer = styled.div`
  padding: 12px 0px;
`;
const HeaderRow = styled(FlexRow)`
  justify-content: space-between;
  padding-bottom: 4px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${colors.border.light};
`;
const StyledInput = styled(Input)`
  border: 1px solid ${colors.border.light};
  width: 100%;
  height: 48px;
  padding 4px 8px;
`;
const CheckBoxInput = styled.div`
  width: 50%;
  margin-bottom: 8px;
  label {
    padding-left: 4px;
  }
`;
const BoxContainer = styled.div`
  display: flex;
  padding-bottom: 8px;
  flex-wrap: wrap;
`;
const Title = styled.div`
  font-weight: 600;
  color: #2a3f4d;
`;
const Subtext = styled.div`
  color: ${colors.text.accent};
`;

interface Props {
  title: string;
  isRequired: boolean | undefined;
  onChange: Function;
  options: string[];
  selectedItems: string[];
  showUserSubmission?: boolean;
  updateUserSubmission?: Function;
  userSubmissionValue?: string;
  userSubmissionPlaceholder?: string;
}

export const FormCheckbox = ({
  isRequired,
  onChange,
  title,
  options,
  selectedItems,
  showUserSubmission,
  updateUserSubmission,
  userSubmissionValue,
  userSubmissionPlaceholder,
}: Props) => {
  return (
    <InputContainer>
      <HeaderRow>
        <Title>{title}</Title>
        <Subtext>{!isRequired && 'Optional'}</Subtext>
      </HeaderRow>
      <BoxContainer>
        {options.map((option) => (
          <CheckBoxInput key={option}>
            <Checkbox
              checked={selectedItems.includes(option)}
              value={option}
              onChange={(e) => onChange(e)}
            />
            <label htmlFor={option}>{option}</label>
          </CheckBoxInput>
        ))}
      </BoxContainer>
      {showUserSubmission && updateUserSubmission && (
        <StyledInput
          placeholder={userSubmissionPlaceholder}
          value={userSubmissionValue}
          onChange={(e) => updateUserSubmission(e)}
          type="text"
        />
      )}
    </InputContainer>
  );
};
