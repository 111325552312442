import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { styles, Library } from '@mq/gatsby-components';

import { ProfileContext } from '../components/ProfileContext';

export const NavMainLoginSection: React.FC = () => {
  const userInfo = useContext(ProfileContext);
  const isPublic = !userInfo.loggedIn && userInfo.hasLoaded;
  const isLoggedIn = userInfo.loggedIn && userInfo.hasLoaded;
  return (
    <Container>
      <ButtonRow isVisible={isPublic}>
        <BlueButton to={'/contact'} className="thin">
          Contact Us
        </BlueButton>
        <PrimaryOutlineLink className="margin-left thin" href={'/s/'}>
          Log In
        </PrimaryOutlineLink>
      </ButtonRow>
      <ButtonRow isVisible={isLoggedIn}>
        <PrimaryOutlineLink className="thin" href="/s">
          Go To Marquee
        </PrimaryOutlineLink>
      </ButtonRow>
    </Container>
  );
};

const { BlueButton, FlexRow, PrimaryOutlineLink } = Library;
const { respondTo } = styles.mixins;

const Container = styled.div`
  min-width: 180px;
  padding-right: 96px;
  display: none;
  ${respondTo.md`
    display: inherit;
  `}
  ${respondTo.lg`
    padding-right: 0;
    border-left: 1px solid #2A3F4D;
    padding-left: 24px;
    margin-left: 14px;
  `}
`;
const ButtonRow = styled(FlexRow)`
  align-items: center;
  height: 100%;
  display: ${({ isVisible }) => (isVisible ? 'inherit' : 'none')};
`;
