import React from 'react';
import styled from '@emotion/styled';

import PageComponent from '../PageComponent';
import CardGalleryList from '../CardGalleryList';
import {
  PageComponentText,
  SectionTextLight,
  SectionSubTextLight,
} from '../../styles/Library';
import { respondTo } from '../../styles/mixins';
import { colors } from '../../styles/variables';
import {
  CardGalleryBlock,
  CardGalleryBlock_cards_Craft_cardGallery_cardGallery_Entry as Card,
} from '@mq/generated-types/dist/CardGalleryBlock';

interface CardGalleryI extends CardGalleryBlock {
  cards: Card[];
  /* 2 types of cards are possible, so we're explicitly setting
   this to be the CardGallery card here to avoid lots of JSX conditionals */
}
interface PropsI {
  craft: CardGalleryI;
}

const TextContainer = styled.div`
  ${respondTo.md`
    padding-bottom: 12px;
  `}
`;

const CardGallery: React.FC<PropsI> = ({ craft }: PropsI) => {
  const { blockTitle, cards, description } = craft;
  return (
    <PageComponent
      backgroundColor={colors.brand.primary}
      borderColor={colors.border.componentDark}
    >
      {(blockTitle || description) && (
        <TextContainer>
          <SectionTextLight>{blockTitle}</SectionTextLight>
          <SectionSubTextLight>{description}</SectionSubTextLight>
        </TextContainer>
      )}
      <CardGalleryList list={cards || []} />
    </PageComponent>
  );
};

export default CardGallery;
