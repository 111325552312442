export const isQA: boolean = process.env.GATSBY_ENV === 'QA';

export const isCorrectAudience = (
  audiences: (string | null)[] | null,
): boolean | undefined =>
  audiences?.includes((process.env.GATSBY_AUDIENCE_LEVEL || '').toLowerCase());

type HasDateUpdated = { dateUpdated: string };
export const getMostRecentDraft = <T extends HasDateUpdated>(
  draftsList: T[],
): T => {
  return draftsList.sort(
    (a, b) => +new Date(b.dateUpdated) - +new Date(a.dateUpdated),
  )[0];
};
