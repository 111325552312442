import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { respondTo } from '../styles/mixins';
import { ProductMetaItem, CenteredFlex } from '../styles/Library';
import { colors } from '../styles/variables';
import Container from './Container';

interface GridListI {
  className?: string;
  list: ReactNode[];
  renderItem: Function;
  theme?: string;
}

const ListContainer = styled(Container)`
  background-color: inherit;
  display: flex;
  align-items: center;
  padding: 0px !important;
  justify-content: center;
`;
const GridItemsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  ${respondTo.md`
    flex-direction: row;
  `}
`;
const Content = styled(CenteredFlex)`
  padding: 48px;
  height: 100%;
  width: 100%;
  background-color: ${(props: { themeColor?: string }) =>
    props.themeColor === 'grey' ? colors.offwhite : colors.white};
`;

export const GridList: React.FC<GridListI> = ({
  list,
  renderItem,
  theme,
}: GridListI) => {
  return (
    <ListContainer>
      <GridItemsList>
        {list.map((item) => (
          <ProductMetaItem key={`${JSON.stringify(item)}`}>
            <Content themeColor={theme}>{renderItem(item)}</Content>
          </ProductMetaItem>
        ))}
      </GridItemsList>
    </ListContainer>
  );
};
