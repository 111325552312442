import React from 'react';
import styled from '@emotion/styled';
import { GetApp } from '@material-ui/icons';

import AssetPlaceholder from '../AssetPlaceholder';
import PageComponent from '../PageComponent';
import Container from '../Container';
import Trisection from '../Trisection';
import CTAFooter from '../SectionCTAFooter';
import { colors } from '../../styles/variables';
import { respondTo } from '../../styles/mixins';
import {
  BlueHighlightText,
  ComponentText,
  ComponentSubText,
  IMG,
} from '../../styles/Library';

import {
  CollateralBlock,
  CollateralBlock_category_Craft_downloadCategories_Category,
} from '@mq/generated-types/dist/CollateralBlock';
import {
  Statements,
  Statements_statements,
  Statements_statements_icon,
} from '@mq/generated-types/dist/Statements';

const Content = styled.div`
  display: flex;
`;
const ImageContainer = styled.div`
  display: none;
  position: relative;
  ${respondTo.md`
    display: flex;
    width: 30%;
  `}
`;
const MobileImage = styled(ImageContainer)`
  display: flex;
  position: relative;
  ${respondTo.md`
    display: none;
    width: 30%;
  `}
`;
const InfoContainer = styled.div`
  border: 1px solid ${colors.brand.accent};
  width: 100%;
  ${respondTo.md`
    width: 70%;
    border-left: none;
  `}
`;
const InfoContent = styled.div`
  padding: 32px;
`;
const IconContainer = styled(IMG)`
  height: 32px;
  width: 32px;
  ${respondTo.md`
    height: 40px;
    width: 40px;
  `}
  object-fit: contain;
`;
const CTAText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: ${colors.text.light};
`;
const DownloadIcon = styled.div`
  font-size: 1.38rem;
  color: ${colors.text.light};
`;
const FloatingCategory = styled.div`
  position: absolute;
  top: 32px;
  left: 32px;
`;
const TrisectionContainer = styled.div`
  padding-top: 24px;
  ${respondTo.md`
    padding-top: 32px;
  `}
`;
const MobileIMG = styled(IMG)`
  object-fit: cover;
  height: 179px;
`;
const ItemTextContainer = styled.div`
  width 100%;
  display: flex;
  flex-direction: row;
  ${respondTo.md`
    flex-direction: column;
  `}
`;
const ItemText = styled(ComponentSubText)`
  margin-left: 16px;
  font-size: 1rem !important;
  ${respondTo.md`
    margin-left: 0;
    margin-top: 16px;
  `}
`;
const TitleText = styled(ComponentText)`
  font-weight: 600;
  width: 100%;
`;
const SubText = styled(ComponentSubText)`
  width: 100%;
`;

interface CraftData {
  craft: CollateralBlock;
}

const Collateral: React.FC<CraftData> = ({ craft: newCraft }) => {
  const {
    category,
    blockTitle,
    description,
    image,
    statements,
    linkLabel,
    document,
  } = newCraft;

  const typedCategory =
    category as CollateralBlock_category_Craft_downloadCategories_Category[];
  const typedStatements = statements as Statements[];
  return (
    <>
      <PageComponent>
        <MobileImage>
          {typedCategory && typedCategory[0] && (
            <FloatingCategory>
              <BlueHighlightText>{typedCategory[0].title}</BlueHighlightText>
            </FloatingCategory>
          )}
          {image && image[0] && image[0].url && image[0].title ? (
            <MobileIMG src={image[0].url} alt={image[0].title} />
          ) : (
            <AssetPlaceholder />
          )}
        </MobileImage>
        <Content>
          <ImageContainer>
            {typedCategory && typedCategory[0] && (
              <FloatingCategory>
                <BlueHighlightText>{typedCategory[0].title}</BlueHighlightText>
              </FloatingCategory>
            )}
            {image && image[0] && image[0].url && image[0].title ? (
              <IMG src={image[0].url} alt={image[0].title} />
            ) : (
              <AssetPlaceholder />
            )}
          </ImageContainer>
          <InfoContainer>
            <InfoContent>
              <TitleText className="full-width">{blockTitle}</TitleText>
              <SubText className="full-width">{description}</SubText>
              {typedStatements.map((statement: any) => (
                <TrisectionContainer
                  key={`${statement.id}-${statement.__typename}`}
                >
                  <Trisection
                    className="small-content"
                    list={statement?.statements}
                    renderItem={(s: Statements_statements | null) =>
                      s && s.id && s.label && s.icon ? (
                        <ItemTextContainer key={s.label}>
                          {s.icon.map(
                            (svg: Statements_statements_icon | null) =>
                              svg && svg.id && svg.url && svg.title ? (
                                <IconContainer
                                  key={svg.id}
                                  src={svg.url}
                                  alt={svg.title}
                                />
                              ) : null,
                          )}
                          <ItemText className="full-width">{s.label}</ItemText>
                        </ItemTextContainer>
                      ) : null
                    }
                  />
                </TrisectionContainer>
              ))}
            </InfoContent>
            {document && document[0] && document[0].url && (
              <CTAFooter
                backgroundColor={colors.blue}
                ctaLink={document[0].url || ''}
              >
                {linkLabel && (
                  <CTAText>
                    <span>{linkLabel.toUpperCase()}</span>
                  </CTAText>
                )}
                <DownloadIcon>
                  <GetApp fontSize="inherit" />
                </DownloadIcon>
              </CTAFooter>
            )}
          </InfoContainer>
        </Content>
      </PageComponent>
    </>
  );
};

export default Collateral;
