import * as React from 'react';
import styled from '@emotion/styled';
import { withPrefix } from 'gatsby';

import Container from '../Container';
import { IMG } from '../../styles/Library';
import { colors } from '../../styles/variables';
import { BlueButton, PrimaryOutlineButton } from '../../styles/Library';
import { respondTo } from '../../styles/mixins';

import CraftAsset from '../CraftAsset';

import { IntroPageBlock } from '@mq/generated-types/dist/IntroPageBlock';

interface ProductContainerI {
  assetPosition: string;
}
interface CraftData {
  craft: IntroPageBlock;
}
interface BackgroundProps {
  backgroundImgUrl?: string;
}
const Background = styled.div`
  background-color: ${colors.brand.primary};
  background-image: url('${({ backgroundImgUrl }: BackgroundProps) =>
    backgroundImgUrl ? backgroundImgUrl : ``}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
const MarqueeLogo = styled(IMG)`
  height 15px;
  width: 111px;
  object-fit: contain;
`;
const ProductContainer = styled(Container)`
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${respondTo.md`
    flex-direction: row;
  `}
`;
const ProductInfo = styled.div`
  width: 100%;
  margin-bottom: 40px;
  ${respondTo.md`
    padding-right: 80px;
    max-width: 55%;
    margin-bottom: 24px;
  `}
`;
const ProductImage = styled.div``;
const ProductHeading = styled.h1`
  font-size: 3rem;
  ${respondTo.xl`
    font-size: 4rem;
  `}
  font-weight: 700;
  color: ${colors.text.light};
  padding-bottom: 5px;
`;
const ProductText = styled.div`
  color: ${colors.text.accentlight};
  font-size: 1.2rem;
  ${respondTo.md`
    font-size: 1.38rem;
  `}
  font-weight: 300;
  padding-bottom: 40px;
`;

const IntroPage: React.FC<CraftData> = ({ craft }) => {
  const {
    blockTitle,
    description,
    foregroundImage,
    backgroundImage,
    primaryButtonLabel,
    primaryButtonLink,
    secondaryButtonLabel,
    secondaryButtonLink,
  } = craft;

  const backgroundImg =
    backgroundImage && backgroundImage[0] ? backgroundImage[0] : null;

  return (
    <Background backgroundImgUrl={backgroundImg ? backgroundImg.url || '' : ''}>
      <ProductContainer>
        <ProductInfo>
          <MarqueeLogo
            src={withPrefix('/marquee_assets/MarqueePageLogo.png')}
          />
          <ProductHeading>{blockTitle}</ProductHeading>
          <ProductText>{description}</ProductText>
          {primaryButtonLink && primaryButtonLabel && (
            <BlueButton to={primaryButtonLink || '/'}>
              {primaryButtonLabel || ''}
            </BlueButton>
          )}
          {secondaryButtonLink && secondaryButtonLabel && (
            <PrimaryOutlineButton
              className="margin-left"
              to={secondaryButtonLink || '/'}
            >
              {secondaryButtonLabel || ''}
            </PrimaryOutlineButton>
          )}
        </ProductInfo>
        {foregroundImage && foregroundImage[0] && (
          <ProductImage>
            <CraftAsset asset={foregroundImage[0]} />
          </ProductImage>
        )}
      </ProductContainer>
    </Background>
  );
};

export default IntroPage;
