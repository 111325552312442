import styled from '@emotion/styled';
import React from 'react';
import '@mq/aurora-components/dist/style.css';
import { Menu } from '@mq/aurora-components/dist/menu';
import { Dropdown } from '@mq/aurora-components/dist/dropdown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { colors } from '../styles/variables';
import { useQAAudience } from '../hooks/useQAAudience';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  width: 100vw;
  border-bottom: 1px solid ${colors.brand.accent};
  background: ${colors.yellow};
`;

const Link = styled.a`
  padding: 5px;
  margin: 2px 5px;
  font-size: 16px;

  &:hover,
  &.active {
    background-color: #186ade;
    border-radius: 5px;
    color: ${colors.white};
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
  }
  &.active {
    cursor: default;
  }
`;

const DropdownButton = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.yellowDark};
  width: 140px;
  padding: 0px 16px;

  span {
    text-transform: capitalize;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  margin-left: 30px;

  h4 {
    margin: 0;
    margin-left: 8px;
    margin-top: 2px;
    font-size: 16px;
  }
`;

const SVGWrapper = styled.div`
  background-color: ${colors.yellowDark};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
`;

const MenuItem = styled(Menu.Item)`
  background-color: #f2f5f7;
  border-radius: 2px 2px 0 0;
  border: 1px solid #c1ccd6;
  font-size: 16px;
  padding: 5px 10px;
  font-weight: 600;
  cursor: default;
`;

const QABanner: React.FC = () => {
  const audience = useQAAudience();

  const menu = (
    <Menu
      style={{
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'GoldmanSans',
        padding: '0 0 2px',
      }}
    >
      <MenuItem>Audience</MenuItem>
      <Link
        className={audience === 'public' ? 'active' : ''}
        href="?audience=public"
      >
        Public
      </Link>
      <Link
        className={audience === 'private' ? 'active' : ''}
        href="?audience=private"
      >
        Protected
      </Link>
      <Link
        className={audience === 'internal' ? 'active' : ''}
        href="?audience=internal"
      >
        Internal
      </Link>
    </Menu>
  );

  return (
    <Root className="banner">
      <LabelWrapper className="this-is-the-label">
        <SVGWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            // height="9"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
          </svg>
        </SVGWrapper>
        <h4>Preview</h4>
      </LabelWrapper>

      <Dropdown
        trigger={['click']}
        overlay={menu}
        style={{
          backgroundColor: '#f7e379',
        }}
      >
        <DropdownButton href="#">
          <span>{audience}</span>
          <ExpandMoreIcon fontSize="inherit" />
        </DropdownButton>
      </Dropdown>
    </Root>
  );
};

export default QABanner;
