import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { respondTo } from '../styles/mixins';
import { ProductMetaItem, CenteredFlex } from '../styles/Library';
import Container from './Container';

interface TrisectionI {
  className?: string;
  list: ReactNode[];
  renderItem: Function;
}

const StyledTrisection = styled(Container)`
  background-color: inherit;
  display: flex;
  align-items: center;
  padding: 0px !important;
  justify-content: center;
`;
const Content = styled(CenteredFlex)`
  padding: 48px;
  &.small-content {
    padding: 16px;
  }
`;
const TrisectionContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${respondTo.md`
    flex-direction: row;
  `}
`;

const Trisection: React.FC<TrisectionI> = ({
  list,
  renderItem,
  className,
}: TrisectionI) => {
  const visibleList = list ? list.slice(0, 3) : [];
  return (
    <StyledTrisection>
      <TrisectionContent>
        {visibleList.map((item) => (
          <ProductMetaItem key={`${JSON.stringify(item)}`}>
            <Content className={className}>{renderItem(item)}</Content>
          </ProductMetaItem>
        ))}
      </TrisectionContent>
    </StyledTrisection>
  );
};

export default Trisection;
