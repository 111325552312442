import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../../styles/variables';
import { respondTo } from '../../styles/mixins';

import Container from '../Container';

const AnchorLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  margin-right: 8px;
  color: ${colors.text.light};
  height: 65px;
  ${respondTo.md`
    margin-right: 13px;
  `}
  &:hover {
    background-color: ${colors.brand.tertiary};
  }
`;
const Background = styled.div`
  background-color: ${colors.brand.secondary};
`;
const ListContainer = styled(Container)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overfly-style: none;
  scrollbar-width: none;
  &.scrollable::-webkit-scrollbar {
    display: none;
  }
`;
const ALink = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

interface NavSubI {
  // lock in the anchor to use the title (as slug)
  list: {
    id: string;
    title: string;
    slug: string;
  }[];
}

export const NavigationSub = ({ list }: NavSubI) => (
  <Background>
    <ListContainer className="scrollable">
      {list.map((listItem) => (
        <ALink key={listItem.id} href={`#${listItem.slug}`}>
          <AnchorLink>{listItem.title}</AnchorLink>
        </ALink>
      ))}
    </ListContainer>
  </Background>
);

export default NavigationSub;
