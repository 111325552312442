import React from 'react';
import styled from '@emotion/styled';

import PageComponent from '../PageComponent';
import QuestionTable from '../QuestionTable';
import {
  CollapseRow,
  FlexRow,
  SectionTextLight,
  SectionSubTextLight,
  SecondaryButton,
} from '../../styles/Library';
import { colors } from '../../styles/variables';
import { QuestionsBlock } from '@mq/generated-types/dist/QuestionsBlock';
import { respondTo } from '../../styles/mixins';

const ButtonRow = styled(FlexRow)`
  align-items: center;
  display: none;
  ${respondTo.md`
    display: inherit;
  `}
`;
const MobileButtonRow = styled(ButtonRow)`
  display: inherit;
  padding-top: 24px;
  ${respondTo.md`
    display: none;
  `}
`;
const Content = styled(CollapseRow)`
  flex: 1;
  color: ${colors.text.light};
`;
const CTAPrep = styled.div`
  color: #c1ccd6;
  font-weight: 600;
  padding-right: 24px;
  font-size: 1rem;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${respondTo.md`
    padding-right: 32px;
    width: 58%;
  `}
`;
const TextInfo = styled.div`
  flex: 1;
  flex-direction: column;
  padding-bottom: 42px;
`;

interface QuestionsI {
  craft: QuestionsBlock;
}

const Questions: React.FC<QuestionsI> = ({ craft }: QuestionsI) => {
  const {
    blockTitle,
    buttonPrompt,
    buttonLabel,
    buttonLink,
    description,
    questionList,
  } = craft;
  const renderButtonRow = () => (
    <>
      <CTAPrep>{buttonPrompt}</CTAPrep>
      <SecondaryButton to={buttonLink || ''}>
        {buttonLabel || ''}
      </SecondaryButton>
    </>
  );
  return (
    <PageComponent
      backgroundColor={colors.blue}
      borderColor={colors.blueaccent}
    >
      <Content>
        <Info>
          <TextInfo>
            <SectionTextLight className="full-width">
              {blockTitle}
            </SectionTextLight>
            <SectionSubTextLight className="full-width">
              {description}
            </SectionSubTextLight>
          </TextInfo>
          <ButtonRow>{renderButtonRow()}</ButtonRow>
        </Info>
        <QuestionTable questionList={questionList} />
        <MobileButtonRow>{renderButtonRow()}</MobileButtonRow>
      </Content>
    </PageComponent>
  );
};

export default Questions;
