import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PlayArrow } from '@material-ui/icons';

import PageComponent from './PageComponent';
import {
  CenteredFlex,
  BlueButton,
  PrimaryOutlineButton,
  IMG,
  SectionSubTextLight,
} from '../styles/Library';
import { colors } from '../styles/variables';
import { respondTo } from '../styles/mixins';
import { Asset } from '@mq/generated-types/dist/Asset';
import { VideoModal } from './VideoModal';

const Intro = styled.div`
  position: relative;
`;
const Content = styled.div`
  color: ${colors.text.light};
  min-height: 115px;
  ${respondTo.md`
    min-height: 250px;
  `}
  ${respondTo.xl`
    min-height: 400px;
  `}
`;
const ButtonRow = styled.div`
  padding: 35px 0 110px 0;
  min-height: 75px;
`;
const ThumbnailContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 110%;
  transform: translate(-50%, -50%);
  width: 451px;
  font-size: 6rem;
  z-index: 2;
  ${respondTo.xs`
    width: 100%;
  `}
  ${respondTo.sm`
    width: 553px;
  `}
  ${respondTo.md`
    top:  125%;
    width: 753px;
  `}
  ${respondTo.xl`
    top:  125%;
    width: 1168px;
  `}
`;

const PlayIconContainer = styled(CenteredFlex)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 130px;
  border-radius: 65px;
  border: 2px solid ${colors.blue};
  font-size: 100px;
  color: ${colors.blue};
  background-color: ${colors.lightblue};
  :hover {
    cursor: pointer;
    height: 140px;
    width: 140px;
    border-radius: 70px;
    font-size: 110px;
  }
`;
const Logo = styled(IMG)`
  max-width: 75%;
  max-height: 100px;
  object-fit: contain;
  object-position: 0 0;
`;

interface HomeBlockI {
  craft: {
    logo: Asset;
    description: string;
    primaryButtonLabel: string;
    primaryButtonLink: string;
    secondaryButtonLabel: string;
    secondaryButtonLink: string;
    image: Asset;
    video: Asset;
  };
}

const IntroHomeBlock: React.FC<HomeBlockI> = ({ craft }: HomeBlockI) => {
  const {
    description,
    primaryButtonLabel,
    primaryButtonLink,
    secondaryButtonLink,
    secondaryButtonLabel,
    image,
    video,
    logo,
  } = craft;
  const selectedImage = image[0];
  const selectedVideo = video[0];
  const selectedLogo = logo[0];
  const [isModalVisible, setModalVisible] = useState(false);
  return (
    <Intro>
      <VideoModal
        close={() => setModalVisible(false)}
        isVisible={isModalVisible}
        videoURL={selectedVideo?.url || ''}
      />
      <PageComponent
        backgroundColor={colors.brand.primary}
        borderColor={colors.border.componentLight}
      >
        <Content>
          {selectedLogo?.url && (
            <Logo src={selectedLogo.url} alt={selectedLogo.title || ''} />
          )}
          <SectionSubTextLight>{description}</SectionSubTextLight>
          <ButtonRow>
            <BlueButton to={primaryButtonLink} className="thick">
              {primaryButtonLabel}
            </BlueButton>
            <PrimaryOutlineButton
              className="margin-left thick"
              to={secondaryButtonLink}
            >
              {secondaryButtonLabel}
            </PrimaryOutlineButton>
          </ButtonRow>
          <ThumbnailContainer>
            {selectedImage?.url && !selectedVideo?.url && (
              <IMG src={selectedImage.url} alt={selectedImage.title || ''} />
            )}
            {selectedImage?.url && selectedVideo && selectedVideo.url && (
              <IMG src={selectedImage.url} alt={selectedImage.title || ''} />
            )}
            {selectedVideo && selectedVideo.url && (
              <PlayIconContainer onClick={() => setModalVisible(true)}>
                <PlayArrow fontSize="inherit" />
              </PlayIconContainer>
            )}
          </ThumbnailContainer>
        </Content>
      </PageComponent>
      <PageComponent>
        <Content />
      </PageComponent>
    </Intro>
  );
};

export default IntroHomeBlock;
