import React from 'react';
import styled from '@emotion/styled';

import { FlexRow } from '../styles/Library';
import { colors } from '../styles/variables';
import { Input } from '@mq/aurora-components/dist/input';

const InputContainer = styled.div`
  padding: 12px 0px;
`;
const HeaderRow = styled(FlexRow)`
  justify-content: space-between;
  padding-bottom: 4px;
`;
const StyledInput = styled(Input)`
  border: 1px solid ${colors.border.light};
  width: 100%;
  height: ${({ type }: { type?: string }) =>
    type === 'textarea' ? '96' : '48'}px;
  padding: 4px 8px;
  resize: none;
`;
const Title = styled.div`
  font-weight: 600;
  color: #2a3f4d;
`;
const Subtext = styled.div`
  color: ${colors.text.accent};
`;

interface Props {
  title: string;
  isRequired: boolean;
  onChange: Function;
  value: string | string[];
  type: string;
}
export const FormInput = ({
  isRequired,
  onChange,
  title,
  type,
  value,
}: Props) => {
  return (
    <InputContainer>
      <HeaderRow>
        <Title>{title}</Title>
        <Subtext>{!isRequired && 'Optional'}</Subtext>
      </HeaderRow>
      <StyledInput value={value} onChange={(e) => onChange(e)} type={type} />
    </InputContainer>
  );
};
