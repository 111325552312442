import * as React from 'react';
import styled from '@emotion/styled';

import { widths } from '../styles/variables';
import { getEmSize, respondTo } from '../styles/mixins';

const StyledContainer = styled.div`
  position: relative;
  padding: 32px 24px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${getEmSize(widths.xxl)}em;
  max-width: 740px;
  ${respondTo.md`
    max-width: 1100px;
    padding: 56px 24px;
  `}
  ${respondTo.xl`
    max-width: 1168px;
    padding: 72px 0px;
  `}
`;

interface ContainerProps {
  className?: string;
}

const Container: React.FC<ContainerProps> = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

export default Container;
