import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../../styles/variables';
import PageComponent from '../PageComponent';
import CardThreeColumnList from '../CardThreeColumnList';
import {
  SectionTextDark,
  SectionTextLight,
  SectionSubTextDark,
  SectionSubTextLight,
} from '../../styles/Library';
import { CardThreeColumnBlock as CardI } from '@mq/generated-types/dist/CardThreeColumnBlock';
import { respondTo } from '../../styles/mixins';

interface CardGalleryI {
  craft: CardI;
}

const TextContainer = styled.div`
  ${respondTo.md`
    padding-bottom: 12px;
  `}
`;

const CardThreeColumnBlock: React.FC<CardGalleryI> = ({
  craft,
}: CardGalleryI) => {
  const { backgroundImage, blockTitle, description, theme, cards } = craft;
  const isDarkTheme = theme === 'dark';
  return (
    <PageComponent
      backgroundColor={isDarkTheme ? colors.brand.primary : colors.white}
      borderColor={
        isDarkTheme ? colors.border.componentDark : colors.border.componentLight
      }
      backgroundImg={backgroundImage}
    >
      {(blockTitle || description) && (
        <TextContainer>
          {isDarkTheme ? (
            <SectionTextLight>{blockTitle}</SectionTextLight>
          ) : (
            <SectionTextDark>{blockTitle}</SectionTextDark>
          )}
          {isDarkTheme ? (
            <SectionSubTextLight>{description}</SectionSubTextLight>
          ) : (
            <SectionSubTextDark>{description}</SectionSubTextDark>
          )}
        </TextContainer>
      )}
      <CardThreeColumnList list={cards} isDarkTheme={isDarkTheme} />
    </PageComponent>
  );
};

export default CardThreeColumnBlock;
