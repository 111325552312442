import React from 'react';
import styled from '@emotion/styled';
import { LockOpen } from '@material-ui/icons';

import {
  CenteredFlex,
  ComponentText,
  FlexRow,
  IconContainer,
  SectionSubTextDark,
  UL,
  BlueButton,
  GrayButton,
} from '../../styles/Library';
import { colors } from '../../styles/variables';

import PageComponent from '../PageComponent';
import { TeaserBlock } from '@mq/generated-types/dist/TeaserBlock';

const Content = styled.span`
  width: auto;
  min-width: 50%;
  padding: 64px;
  background-color: ${colors.white};
  border-radius: 2px;
`;
const List = styled(UL)`
  padding: 20px 0px;
`;
const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;
`;
const ListItemText = styled(SectionSubTextDark)`
  padding-left: 8px;
`;
const NonLinkText = styled.div`
  padding: 0px 16px;
`;
const IconRow = styled(FlexRow)`
  align-items: center;
`;
const CustomTitle = styled(ComponentText)`
  line-height: 2.4rem;
`;

interface CraftResponseI {
  // craft: UnauthCTAI;
  craft: TeaserBlock;
}

const Teaser: React.FC<CraftResponseI> = ({ craft }: CraftResponseI) => {
  const {
    audience,
    blockTitle,
    list,
    primaryButtonLabel,
    primaryButtonLink,
    secondaryButtonLabel,
    secondaryButtonLink,
  } = craft;
  return (
    <PageComponent
      backgroundColor={colors.brand.primary}
      borderColor={colors.border.componentDark}
    >
      <CenteredFlex>
        <Content>
          <CustomTitle>{blockTitle}</CustomTitle>
          <List>
            {list?.map((item) =>
              item ? (
                <ListItem key={String(item.item)}>
                  <IconContainer size="xs">
                    <LockOpen fontSize="inherit" />
                  </IconContainer>
                  <ListItemText>{item.item}</ListItemText>
                </ListItem>
              ) : null,
            )}
          </List>
          <IconRow>
            {primaryButtonLink && primaryButtonLabel && (
              <BlueButton type="blue" to={primaryButtonLink}>
                {primaryButtonLabel}
              </BlueButton>
            )}

            {primaryButtonLink &&
              primaryButtonLabel &&
              secondaryButtonLink &&
              secondaryButtonLabel && <NonLinkText>OR</NonLinkText>}

            {secondaryButtonLink && secondaryButtonLabel && (
              <GrayButton to={secondaryButtonLink}>
                {secondaryButtonLabel}
              </GrayButton>
            )}
          </IconRow>
        </Content>
      </CenteredFlex>
    </PageComponent>
  );
};

export default Teaser;
