import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { ArrowForward } from '@material-ui/icons';
import { CardGalleryBlock_cards_Craft_cardGallery_cardGallery_Entry as Card } from '@mq/generated-types/dist/CardGalleryBlock';

import { colors } from '../styles/variables';
import {
  CenteredFlex,
  FlexRow,
  IMG,
  SectionSubTextLight,
  SubComponentSubTextLight,
} from '../styles/Library';
import { respondTo } from '../styles/mixins';

const WideCard = styled(Link)`
  background-color: ${colors.brand.secondary};
  color: ${colors.text.light};
  padding: 32px 24px 24px 32px;
  width: 100%;
  ${respondTo.md`
    width: 58%;
  `};
  margin-top: 32px;
  border: 1px solid ${colors.brand.secondary};
  &:hover {
    border: 1px solid ${colors.blue};
    text-decoration: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :nth-of-type(even) {
    ${respondTo.md`
      margin-left: 32px;
    `};
  }
`;
const NarrowCard = styled(WideCard)`
  ${respondTo.md`
    width: 37.7%;
  `};
  ${respondTo.xl`
  width: 39%;
`};
`;
const CardTitle = styled(SectionSubTextLight)`
  color: ${colors.text.light};
  font-weight: 600;
  padding-bottom: 8px;
`;
const ImageRow = styled(FlexRow)`
  justify-content: space-between;
`;
const HoverIconContainer = styled(CenteredFlex)`
  height: 48px;
  width: 48px;
  background-color: ${colors.blue};
  visibility: hidden;
  &.hovered {
    visibility: visible;
  }
`;
const AssetContainer = styled.div`
  width: 100px;
  height: 100px;
`;

interface GalleryItemI {
  isWide: boolean;
  card: Card;
}

const generateCardContent = (cardItem: Card, isHovered: boolean) => (
  <>
    <div>
      <CardTitle>{cardItem.title}</CardTitle>
      <SubComponentSubTextLight>
        {cardItem.description}
      </SubComponentSubTextLight>
    </div>
    <ImageRow>
      <HoverIconContainer className={isHovered ? 'hovered' : ''}>
        <ArrowForward fontSize="inherit" />
      </HoverIconContainer>
      <AssetContainer>
        {cardItem.image && cardItem.image[0]?.url && (
          <IMG
            src={cardItem.image[0].url}
            alt={cardItem.image[0].title || ''}
          />
        )}
      </AssetContainer>
    </ImageRow>
  </>
);
const CardGalleryItem: React.FC<GalleryItemI> = ({
  isWide,
  card,
}: GalleryItemI) => {
  const [isHovered, setIsHovered] = useState(false);
  return isWide ? (
    <WideCard
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      to={card.buttonLink || ''}
    >
      {generateCardContent(card, isHovered)}
    </WideCard>
  ) : (
    <NarrowCard
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      to={card.buttonLink || ''}
    >
      {generateCardContent(card, isHovered)}
    </NarrowCard>
  );
};

export default CardGalleryItem;
