import { useQueryParam, StringParam } from 'use-query-params';

export const useQAAudience = () => {
  const [GATSBY_AUDIENCE] = useQueryParam('audience', StringParam);
  let audience = GATSBY_AUDIENCE || '';

  if (!['public', 'private', 'internal'].includes(audience.toLowerCase())) {
    audience = 'public';
  }

  return audience;
};
