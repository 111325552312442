import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../styles/variables';
import { FlexRow } from '../styles/Library';

const UnselectedCircle = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: ${colors.lightblue};
`;
const IndicatorLine = styled.div`
  height: 3px;
  width: 26px;
  border-top: 1px solid ${colors.lightblue};
`;
const SelectedCircle = styled(UnselectedCircle)`
  background-color: ${colors.blue};
`;
const IndicatorContainer = styled(FlexRow)`
  width: 100%;
  height: 10px;
  align-items: center;
  justify-content: center;
`;

interface Props {
  selectedPage: number;
}
export const PageIndicator = ({ selectedPage }: Props) => {
  return (
    <IndicatorContainer>
      {selectedPage == 1 ? <SelectedCircle /> : <UnselectedCircle />}
      <IndicatorLine />
      {selectedPage == 2 ? <SelectedCircle /> : <UnselectedCircle />}
    </IndicatorContainer>
  );
};
