import React from 'react';
import styled from '@emotion/styled';
import { CheckCircle } from '@material-ui/icons';

import ImageBlock from '../ImageBlock';
import InfoBlock from '../InfoBlock';
import Container from '../Container';
import { BlueOutlineButton, CenteredFlex, UL } from '../../styles/Library';
import { respondTo } from '../../styles/mixins';
import { colors } from '../../styles/variables';
import { FeatureSideBySideBlock } from '@mq/generated-types/dist/FeatureSideBySideBlock';

const StyledContainer = styled(Container)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  ${respondTo.md`
    padding-top: 0 !important;
    padding-bottom: 0!important;
  `}
`;
const Content = styled(CenteredFlex)`
  flex-direction: column;
  align-items: stretch;
  ${respondTo.md`
    flex-direction: row;
  `}
`;
const Text = styled.div`
  font-size: 1.2rem;
  ${respondTo.xl`
   font-size: 1.38rem;
  `}
  font-weight: 600;
  padding-bottom: 16px;
`;
const SubText = styled.div`
  font-size: 1rem;
  padding-bottom: 16px;
`;
const BenefitItem = styled.li`
  padding-bottom: 16px;
  display: flex;
  align-items: flex-start;
  .checkmark {
    color: ${colors.lightblue};
    background-color: ${colors.blue};
    border-radius: 16px;
    margin-top: 3px;
  }
`;
const BenefitText = styled.span`
  padding-left: 8px;
`;

const InfoContent = styled.div``;

interface CraftResponseI {
  craft: FeatureSideBySideBlock;
}

const FeatureSideBySide: React.FC<CraftResponseI> = ({
  craft,
}: CraftResponseI) => {
  const {
    blockTitle,
    description,
    position,
    buttonLabel,
    buttonLink,
    image,
    statements,
    theme,
  } = craft;
  const currentStatements =
    statements &&
    statements[0] &&
    'statements' in statements[0] &&
    statements[0].statements;
  const renderImage = (className) => {
    return image?.map((img: any) =>
      img && img.url && img.title ? (
        <ImageBlock
          className={className}
          key={img.title}
          imgSrc={img.url}
          imgAlt={img.title}
          theme={theme}
        />
      ) : null,
    );
  };
  return (
    <StyledContainer className="sidebyside">
      <Content>
        {renderImage('mobile')}
        {position === 'right' && renderImage('right')}
        <InfoBlock className={position || ''}>
          <InfoContent>
            <Text>{blockTitle}</Text>
            <SubText>{description}</SubText>
            <UL>
              {currentStatements &&
                currentStatements.map((statement) => {
                  if (statement) {
                    return (
                      <BenefitItem key={statement.id || ''}>
                        <CheckCircle className="checkmark" fontSize="inherit" />
                        <BenefitText>{statement.label}</BenefitText>
                      </BenefitItem>
                    );
                  }
                  return null;
                })}
            </UL>
            {buttonLabel && buttonLink && (
              <BlueOutlineButton to={buttonLink}>
                {buttonLabel}
              </BlueOutlineButton>
            )}
          </InfoContent>
        </InfoBlock>
        {position === 'left' && renderImage('left')}
      </Content>
    </StyledContainer>
  );
};

export default FeatureSideBySide;
