import React, { useState } from 'react';
import styled from '@emotion/styled';

import { colors } from '../styles/variables';
import { respondTo } from '../styles/mixins';
import { PageIndicator } from './PageIndicator';
import { ContactFormPages } from './ContactFormPages';

const Background = styled.div`
  background-color: ${colors.white};
  width: 100%;
  padding: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${respondTo.lg`
    width: 50%;
    padding: 104px 65px;
  `}
  ${respondTo.xl`
    padding: 104px 100px;
  `}
`;
const Title = styled.div`
  font-size: 1.2rem;
  ${respondTo.xl`
    font-size: 1.38rem.
  `}
  font-weight: 600;
  text-align: center;
  padding-bottom: 24px;
`;

export interface FormStateI {
  fullName: string;
  company: string;
  jobFunction: string[];
  otherJobFunction: string;
  jobTitle: string;
  interestedMarkets: string[];
  interestedOfferings: string[];
  gsContact: string;
  additionalInfo: string;
}
export const ContactForm = () => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [formState, setFormState] = useState<FormStateI>({
    fullName: '',
    company: '',
    jobFunction: [],
    otherJobFunction: '',
    jobTitle: '',
    interestedMarkets: [],
    interestedOfferings: [],
    gsContact: '',
    additionalInfo: '',
  });
  // update this requiredFields array to set required fields.
  // updating this array will update style and onClick verification.
  const requiredFields = ['fullName', 'company', 'interestedMarkets'];
  const updateFormProp = (prop: string, updatedState: string | string[]) => {
    return setFormState({
      ...formState,
      [prop]: updatedState,
    });
  };
  const updateList = (prop: string, itemToToggle: string) => {
    const oldList = formState[prop];
    let updatedList = [...oldList];
    if (updatedList.includes(itemToToggle)) {
      updatedList = updatedList.filter((i) => i !== itemToToggle);
    } else {
      updatedList.push(itemToToggle);
    }
    updateFormProp(prop, updatedList);
  };
  return (
    <Background>
      <Title>
        {selectedPage == 1 ? `Who are we talking to?` : `How can we help?`}
      </Title>
      <PageIndicator selectedPage={selectedPage} />
      <ContactFormPages
        updateFormProp={updateFormProp}
        updateList={updateList}
        requiredFields={requiredFields}
        formState={formState}
        selectedPageHooks={[selectedPage, setSelectedPage]}
      />
    </Background>
  );
};
