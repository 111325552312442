import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { Cancel } from '@material-ui/icons';

import { colors } from '../styles/variables';
import { respondTo } from '../styles/mixins';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledContainer = styled.div`
  animation: ${fadeIn};
  animation-duration: 500ms;
  width: 100vw;
  height: 110vh;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: 4;
  transition-duration: 1s;
`;
const VideoComponent = styled.video`
  height: auto;
  object-fit: cover;
  ${respondTo.xs`
    width: 315px;
  `}
  ${respondTo.sm`
    width: 550px;
  `}
  ${respondTo.md`
    width: 753px;
  `}
  ${respondTo.lg`
    width: 912px;
  `}
  ${respondTo.xl`
    width: 1012px;
  `}
  :focus {
    outline: none;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  ${respondTo.xs`
    width: 340px;
  `}
  ${respondTo.sm`
    width: 590px;
  `}
  ${respondTo.md`
    width: 783px;
  `}
  ${respondTo.lg`
    width: 955px;
  `}
  ${respondTo.xl`
    width: 1047px;
  `}
`;
const Icon = styled.div`
  font-size: 24px;
  display: flex;
  flex-direction: center;
  align-items: center;
  color: ${colors.white};
  :hover {
    cursor: pointer;
  }
`;
interface ContainerProps {
  close: () => void;
  videoURL?: string;
  isVisible: boolean;
}

export const VideoModal: React.FC<ContainerProps> = ({
  close,
  isVisible,
  videoURL,
}) =>
  isVisible ? (
    <StyledContainer onClick={close}>
      <IconContainer>
        <Icon>
          <Cancel fontSize="inherit" />
        </Icon>
      </IconContainer>
      <VideoComponent autoPlay controls controlsList="nodownload">
        <source src={videoURL} />
      </VideoComponent>
    </StyledContainer>
  ) : null;
