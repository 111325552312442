import React, { useState } from 'react';
import styled from '@emotion/styled';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { Link } from 'gatsby';

import AssetPlaceholder from './AssetPlaceholder';
import {
  CenteredFlex,
  FlexRow,
  IMG,
  SectionSubTextLight,
  IconContainer,
} from '../styles/Library';
import { respondTo } from '../styles/mixins';
import { colors } from '../styles/variables';
import { getTargetIndex } from './utils';

import {
  TabbedVerticalBlock_buttonIcon,
  TabbedVerticalBlock_tabs_Craft_verticalTabs_verticalTabs_Entry_verticalTabs,
} from '@mq/generated-types/dist/TabbedVerticalBlock';

const Content = styled(FlexRow)`
  color: ${colors.text.light};
  border: 1px solid ${colors.border.dark};
  min-height: 400px;
  flex-direction: column;
  ${respondTo.md`
    flex-direction: row;
    min-height: 550px;
  `}
`;

const Tabs = styled.div`
  width: 416px;
  display: none;
  flex-direction: column;
  ${respondTo.md`
    display flex;
  `}
`;
interface TabProps {
  isSelected: boolean;
}
const Tab = styled.a`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ isSelected }: TabProps) =>
    isSelected ? colors.brand.secondary : colors.brand.primary};
  height: 100%;
  padding: ${({ isSelected }: TabProps) =>
    isSelected ? '32px 32px 32px 28px' : '32px'};
  &:hover {
    cursor: pointer;
    background-color: ${colors.brand.secondary};
    text-decoration: none;
  }
  border-right: ${({ isSelected }: TabProps) => (isSelected ? '0px' : '1px')}
    solid ${colors.border.dark};
  border-left: ${({ isSelected }: TabProps) =>
    isSelected
      ? `4px solid ${colors.blue}`
      : `1px solid ${colors.border.dark}`};
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.border.dark};
  }
`;
const TabTitle = styled(SectionSubTextLight)`
  font-weight: 600;
  color: ${colors.text.light};
  padding-bottom: 8px;
`;
const TabDescription = styled.div`
  font-size: 1rem;
  color: ${colors.text.accentlight};
`;
const SelectedContent = styled.div`
  flex: 1;
  background-color: ${colors.brand.secondary};
  padding: 32px;
  ${respondTo.md`
    padding: 76px 56px;
  `}
`;
const SelectedIMG = styled(IMG)`
  object-fit: contain;
`;
const CTAContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding: 24px 32px;
  border-right: 1px solid ${colors.border.dark};
  color: ${colors.bluelink};
  font-size: 1rem;
`;
const MobileText = styled.div`
  display: inherit;
  padding-top 24px;
  ${respondTo.md`
    display: none;
  `}
`;
const MobileInfoRow = styled(CenteredFlex)`
  flex-direction: column;
  flex-grow: 1;
  padding: 0px 16px;
`;
interface DotProps {
  isSelected: boolean;
}
const MobileTabDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin: 8px 12px;
  background-color: ${({ isSelected }: DotProps) =>
    isSelected ? colors.blue : '#D4E4FA'};
`;
const MobileTabDotRow = styled(FlexRow)``;
const MobileTabNav = styled.button`
  background-color: ${colors.brand.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border: 1px solid ${colors.border.dark};
  font-weight: 600;
  color: ${colors.text.light};
`;
const MobileTabs = styled(CenteredFlex)`
  position: relative;
  height: 79px;
  padding: 16px;
  display: inherit;
  ${respondTo.md`
    display: none;
  `}
  border-bottom: 1px solid ${colors.border.dark};
  background-color: ${colors.brand.secondary};
  display: flex;
  flex-direction: row;
`;

interface TabbedVerticalListProps {
  buttonLabel: string | null;
  buttonLink: string | null;
  buttonIcon: (TabbedVerticalBlock_buttonIcon | null)[] | null;
  tabs: TabbedVerticalBlock_tabs_Craft_verticalTabs_verticalTabs_Entry_verticalTabs[];
}

const TabbedVerticalList: React.FC<TabbedVerticalListProps> = ({
  buttonLabel,
  buttonLink,
  buttonIcon,
  tabs,
}: TabbedVerticalListProps) => {
  buttonIcon;
  const [selectedItem, selectItem] = useState(tabs[0]);
  const isSelected = (
    item: TabbedVerticalBlock_tabs_Craft_verticalTabs_verticalTabs_Entry_verticalTabs,
  ) => selectedItem === item;
  const selectNext = () => {
    const nextIndex = getTargetIndex(tabs, selectedItem) || 0;
    const nextItem = tabs[nextIndex];
    selectItem(nextItem);
  };
  const selectPrev = () => {
    const nextIndex = getTargetIndex(tabs, selectedItem, true) || 0;
    const nextItem = tabs[nextIndex];
    selectItem(nextItem);
  };
  return (
    <Content>
      <MobileTabs>
        <MobileTabNav onClick={selectPrev}>
          <KeyboardArrowLeft />
        </MobileTabNav>
        <MobileInfoRow>
          <MobileTabDotRow>
            {tabs.map((listItem) =>
              listItem ? (
                <MobileTabDot
                  key={listItem.id || ''}
                  isSelected={listItem.id === selectedItem.id}
                />
              ) : null,
            )}
          </MobileTabDotRow>
        </MobileInfoRow>
        <MobileTabNav onClick={selectNext}>
          <KeyboardArrowRight />
        </MobileTabNav>
      </MobileTabs>
      <Tabs>
        {tabs.map(
          (
            tab: TabbedVerticalBlock_tabs_Craft_verticalTabs_verticalTabs_Entry_verticalTabs,
          ) => (
            <Tab
              key={tab.id || ''}
              onClick={() => selectItem(tab)}
              isSelected={isSelected(tab)}
            >
              <TabTitle>{tab.blockTitle}</TabTitle>
              {isSelected(tab) && (
                <TabDescription>{tab.description}</TabDescription>
              )}
            </Tab>
          ),
        )}
        {buttonLabel && buttonLink && buttonIcon && (
          <CTAContainer to={buttonLink}>
            <span>{buttonLabel}</span>
            {buttonIcon.length && (
              <IconContainer size="xs">
                {buttonIcon.map((i) =>
                  i && i.id && i.url && i.title ? (
                    <img key={i.id} src={i.url} alt={i.title} />
                  ) : null,
                )}
              </IconContainer>
            )}
            {/* <OpenInNew fontSize="inherit" /> */}
          </CTAContainer>
        )}
      </Tabs>
      <SelectedContent>
        {selectedItem.image?.map((img) =>
          img && img.id && img.url ? (
            <SelectedIMG key={img.id} src={img.url} alt={img.title || ''} />
          ) : (
            <AssetPlaceholder />
          ),
        )}
        <MobileText>
          <TabTitle>{selectedItem.blockTitle}</TabTitle>
          <TabDescription>{selectedItem.description}</TabDescription>
        </MobileText>
      </SelectedContent>
    </Content>
  );
};

export default TabbedVerticalList;
