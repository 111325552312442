import React from 'react';
import styled from '@emotion/styled';
import {
  PeopleRounded,
  AttachMoneyOutlined,
  Phonelink,
} from '@material-ui/icons';

import { Asset } from '@mq/generated-types/dist/Asset';
import Container from '../Container';
import Trisection from '../Trisection';
import { CenteredFlex, IMG, SectionSubTextDark } from '../../styles/Library';
import { colors } from '../../styles/variables';
import { respondTo } from '../../styles/mixins';
import { ProductInfoBlock } from '@mq/generated-types/dist/ProductInfoBlock';

interface ItemI {
  icon: Asset;
  title: string;
  list: {
    item: string;
  }[];
}
interface MetaDataI {
  craft: ProductInfoBlock;
}

const Content = styled(Container)`
  padding: 0 !important;
  ${respondTo.md`
    padding-left: inherit;
    padding-right: inherit;
  `}
`;
const MetaDataText = styled(SectionSubTextDark)`
  font-weight: 600;
  color: ${colors.text.dark};
  padding-bottom: 8px;
`;
const MetaDataSubText = styled.div`
  color: ${colors.text.accent};
  font-size: 1rem;
  display: flex;
  align-items: center;
  .bullet {
    font-size: 0.25rem;
    padding-right: 8px;
  }
`;
const MetaDataContent = styled.div`
  height: 100%;
  width: 100%;
`;
const IconContainer = styled(CenteredFlex)`
  height: 56px;
  width: 56px;
  border-radius: 28px;
  font-size: 2rem;
  background-color: ${colors.lightblue};
  color: ${colors.blue};
  margin-bottom: 24px;
`;
const Background = styled.div`
  background-color: ${colors.offwhite};
`;

const renderButton = (title: string) => {
  if (title === 'Used by') return <PeopleRounded fontSize="inherit" />;
  if (title === 'Supported markets')
    return <AttachMoneyOutlined fontSize="inherit" />;
  if (title === 'Available via') return <Phonelink fontSize="inherit" />;
};

const ProductInfo: React.FC<MetaDataI> = ({ craft }: MetaDataI) => {
  return (
    <Background>
      <Content>
        <Trisection
          list={[
            {
              title: 'Used by',
              list: craft.usedBy,
            },
            {
              title: 'Supported markets',
              list: craft.supportedMarkets,
            },
            {
              title: 'Available via',
              list: craft.apiAvailable,
            },
          ]}
          renderItem={(item: ItemI) => (
            <MetaDataContent>
              <IconContainer>{renderButton(item.title)}</IconContainer>
              <MetaDataText>{item.title}</MetaDataText>
              {item.list.map((subItem) => (
                <MetaDataSubText key={subItem.item || ''}>
                  <span className="bullet">{`\u2588 `}</span>
                  {` ${subItem.item}`}
                </MetaDataSubText>
              ))}
            </MetaDataContent>
          )}
        />
      </Content>
    </Background>
  );
};

export default ProductInfo;
