import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Done } from '@material-ui/icons';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import AssetPlaceholder from './AssetPlaceholder';
import {
  BlueButton,
  CenteredFlex,
  FlexRow,
  IMG,
  UL,
  IconContainer,
  SecondaryButton,
} from '../styles/Library';
import { colors } from '../styles/variables';
import { useInterval } from '../hooks/useInterval';
import { respondTo } from '../styles/mixins';
import {
  TabbedHorizontalBlock,
  TabbedHorizontalBlock_tabs_Craft_horizontalTabs_horizontalTabs_Entry_horizontalTabs as TabI,
} from '@mq/generated-types/dist/TabbedHorizontalBlock';
import { getStartingIndex, getTargetIndex } from './utils';

const Content = styled.div`
  min-height: 500px;
  border: 1px solid ${colors.border.light};
  border-top: none;
`;
const StatementIcon = styled(IconContainer)`
  font-size: 1rem;
`;
const _SELECTED_TAB_BORDER_PX = '4';
const Tabs = styled.div`
  min-height: 89px;
  display: none;
  ${respondTo.md`
    display: flex;
  `}
`;
interface TabProps {
  isSelected: boolean;
}
const Tab = styled(CenteredFlex)`
  flex: 1;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: ${({ isSelected }: TabProps) =>
    isSelected ? `${_SELECTED_TAB_BORDER_PX}px` : '0'};
  background-color: ${({ isSelected }: TabProps) =>
    isSelected ? colors.offwhite : colors.white};
  color: ${({ isSelected }: TabProps) =>
    isSelected ? colors.blue : colors.text.dark};
  border-right: 1px solid ${colors.border.light};
  border-top: ${({ isSelected }: TabProps) =>
      isSelected ? `${_SELECTED_TAB_BORDER_PX}px` : '1px'}
    solid
    ${({ isSelected }: TabProps) =>
      isSelected ? colors.blue : colors.border.light};
  position: relative;
  border-bottom: ${({ isSelected }: TabProps) => (isSelected ? '0px' : '1px')}
    solid ${colors.border.light};
  &:last-child {
    border-right: none;
  }
  :hover {
    background-color: ${colors.offwhite};
    text-decoration: none;
    cursor: pointer;
  }
`;
const TabNumber = styled(CenteredFlex)`
  position: absolute;
  top: ${({ isSelected }: TabProps) => (isSelected ? '-20px' : '-16px')};
  background-color: white;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  border: 1px solid ${colors.border.light};
  padding: 4px;
  font-size: 0.75rem;
`;
const SelectedContent = styled.div`
  min-height: 400px;
  display: flex;
  padding: 32px;
  background-color: ${colors.offwhite};
  justify-content: space-between;
  ${respondTo.md`
    padding: 56px;
  `}
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${respondTo.md`
    width: 60%
  `}
  ${respondTo.xl`
    width: 50%;
  `}
`;
const ImageContainer = styled.div`
  display: flex;
  margin-left: 32px;
  display: none;
  ${respondTo.md`
    display: inherit;
    flex: 1;
  `}
  ${respondTo.xl`
    flex: 1;
  `}
`;
const ItemText = styled.div`
  font-size: 1.2rem;
  ${respondTo.xl`
    font-size: 1.38rem;
  `}
  font-weight: 600;
  padding-bottom: 8px;
`;
const ItemSubText = styled.div`
  font-size: 1rem;
  padding-bottom: 16px;
`;
const LIText = styled(ItemSubText)`
  padding-left: 8px;
`;
const LI = styled.li`
  display: flex;
`;
const CustomIconContainer = styled(IconContainer)`
  color: ${({ isSelected }: TabProps) =>
    isSelected ? colors.text.light : colors.blue};
  background-color: ${({ isSelected }: TabProps) =>
    isSelected ? colors.blue : colors.lightblue};
  font-size: 0.75rem;
`;
const Row = styled(FlexRow)`
  .left-margin {
    margin-left: 16px;
  }
`;
const MobileImageContainer = styled(ImageContainer)`
  display: inherit;
  padding: 0;
  ${respondTo.md`
    display: none;
  `}
`;
const MobileInfoRow = styled(CenteredFlex)`
  flex-direction: column;
  flex-grow: 1;
  padding: 0px 16px;
`;
interface DotProps {
  isSelected: boolean;
}
const MobileTabDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin: 8px 12px;
  background-color: ${({ isSelected }: DotProps) =>
    isSelected ? colors.blue : '#D4E4FA'};
`;
const MobileTabDotRow = styled(FlexRow)``;
const MobileTabNav = styled.button`
  background-color: ${colors.offwhite};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border: 1px solid ${colors.border.light};
  font-weight: 600;
`;
const MobileTabs = styled(CenteredFlex)`
  position: relative;
  height: 79px;
  padding: 16px;
  display: inherit;
  ${respondTo.md`
    display: none;
  `}
  border-bottom: 1px solid ${colors.border.light};
  background-color: ${colors.offwhite};
  display: flex;
  flex-direction: row;
`;
const MobileText = styled.div`
  font-size: 1rem;
  color: ${colors.blue};
`;
const SelectedIMG = styled(IMG)`
  object-fit: contain;
  height: 318px;
  width: auto;
  ${respondTo.md`
    height: auto;
    width: 100%;;
  `}
`;

interface TabbedHorizontalProps {
  list: TabbedHorizontalBlock['tabs'];
  numberTabs: boolean | null;
  transitionTabs: boolean | null;
}
const TabbedHorizontal: React.FC<TabbedHorizontalProps> = ({
  list,
  numberTabs,
  transitionTabs,
}: TabbedHorizontalProps) => {
  if (!list || !list[0]) return null;
  const tabs = 'horizontalTabs' in list[0] ? list[0].horizontalTabs : [];
  if (!tabs || !tabs[0]) return null;
  const [selectedItem, selectItem] = useState<TabI | null>(tabs[0]);
  if (!selectedItem) return null;
  const selectNext = () => {
    const nextIndex = getTargetIndex(tabs, selectedItem) || 0;
    const nextItem = tabs[nextIndex];
    selectItem(nextItem);
  };
  const selectPrev = () => {
    const nextIndex = getTargetIndex(tabs, selectedItem, true) || 0;
    const nextItem = tabs[nextIndex];
    selectItem(nextItem);
  };

  useInterval(() => {
    if (!transitionTabs) return;
    selectNext();
  }, 4000);
  const isSelected = (item: TabI) => item === selectedItem;
  const statements =
    (selectedItem &&
      selectedItem.statements &&
      selectedItem.statements[0] &&
      'statements' in selectedItem.statements[0] &&
      selectedItem.statements[0].statements) ||
    [];
  const primaryLink =
    'primaryButtonLink' in selectedItem && selectedItem.primaryButtonLink;
  const secondaryLink =
    'secondaryButtonLink' in selectedItem && selectedItem.secondaryButtonLink;
  const primaryLabel =
    'primaryButtonLabel' in selectedItem && selectedItem.primaryButtonLabel;
  const secondaryLabel =
    'secondaryButtonLabel' in selectedItem && selectedItem.secondaryButtonLabel;
  const selectedImage = selectedItem.image && selectedItem.image[0];
  return (
    <Content>
      <MobileTabs>
        {numberTabs && (
          <TabNumber isSelected>
            <CustomIconContainer isSelected size="xs">
              {getStartingIndex(tabs, selectedItem) + 1}
            </CustomIconContainer>
          </TabNumber>
        )}
        <MobileTabNav onClick={selectPrev}>
          <KeyboardArrowLeft />
        </MobileTabNav>
        <MobileInfoRow>
          <MobileText>{selectedItem.label}</MobileText>
          <MobileTabDotRow>
            {tabs.map((listItem, index) =>
              listItem ? (
                <MobileTabDot
                  key={listItem.id || ''}
                  isSelected={listItem.id === selectedItem.id}
                />
              ) : null,
            )}
          </MobileTabDotRow>
        </MobileInfoRow>
        <MobileTabNav onClick={selectNext}>
          <KeyboardArrowRight />
        </MobileTabNav>
      </MobileTabs>
      <Tabs>
        {tabs.map((listItem, index) =>
          listItem ? (
            <Tab
              onClick={() => selectItem(listItem)}
              isSelected={isSelected(listItem)}
              key={'id' in listItem ? (listItem.id ? listItem.id : '') : ''}
            >
              {numberTabs && (
                <TabNumber isSelected={isSelected(listItem)}>
                  <CustomIconContainer
                    isSelected={isSelected(listItem)}
                    size="xs"
                  >
                    {index + 1}
                  </CustomIconContainer>
                </TabNumber>
              )}
              {listItem.label}
            </Tab>
          ) : null,
        )}
      </Tabs>
      <SelectedContent>
        <InfoContainer>
          <MobileImageContainer>
            {selectedImage ? (
              <IMG
                src={selectedImage.url || ''}
                alt={selectedImage.title || ''}
              />
            ) : (
              <AssetPlaceholder />
            )}
          </MobileImageContainer>
          <ItemText>{selectedItem?.blockTitle}</ItemText>
          <ItemSubText>{selectedItem?.description}</ItemSubText>
          <UL>
            {statements.map((listItem) => (
              <LI key={listItem?.id || ''}>
                <StatementIcon size="xs">
                  <Done fontSize="inherit" />
                </StatementIcon>
                <LIText>{listItem?.label}</LIText>
              </LI>
            ))}
          </UL>
          <Row>
            {primaryLink && primaryLabel && (
              <BlueButton to={primaryLink || ''}>
                {primaryLabel || ''}
              </BlueButton>
            )}
            {secondaryLink && secondaryLabel && (
              <SecondaryButton className="left-margin" to={secondaryLink}>
                {secondaryLabel}
              </SecondaryButton>
            )}
          </Row>
        </InfoContainer>
        <ImageContainer>
          {selectedImage ? (
            <SelectedIMG
              src={selectedImage.url || ''}
              alt={selectedImage.title || ''}
            />
          ) : (
            <AssetPlaceholder />
          )}
        </ImageContainer>
      </SelectedContent>
    </Content>
  );
};

export default TabbedHorizontal;
