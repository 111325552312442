/* eslint-disable max-len */
import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery, withPrefix } from 'gatsby';
import { ExitToApp } from '@material-ui/icons';
import { Container, styles, Library } from '@mq/gatsby-components';
import GSLOGO from '../images/MarqueeHomeLogo.png';

import {
  MarqueeFooter,
  MarqueeFooter_footerSections_Craft_footerSections_footerSections_Entry as FooterSectionsEntry,
  MarqueeFooter_footerSections_Craft_footerSections_footerSections_Entry_footerLinks as FooterSectionsLinks,
} from '@mq/generated-types/dist/MarqueeFooter';

import { MarqueeDisclaimer } from '@mq/generated-types/dist/MarqueeDisclaimer';

import { isQA, getMostRecentDraft, isCorrectAudience } from '../utils/misc';

const { respondTo } = styles.mixins;
const { colors } = styles.variables;
const { FlexRow, IMG } = Library;

const Background = styled.footer`
  background-color: ${colors.brand.primary};
  border-top: 1px solid ${colors.border.dark};
`;
const FooterContainer = styled(Container)`
  height: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  flex-direction: row;
  ${respondTo.md`
    flex-direction: column;
  `}
`;
const FooterLogoContainer = styled.div`
  display: none;
  flex-direction: column;
  padding-top: 35px;
  padding: 24px 0 24px 0;
  height: 100%;
  width: 23%;
  ${respondTo.md`
    display: flex;
    padding: 32px 0 32px 0;
  `}
`;
const MobileFooterLogoContainer = styled(FooterLogoContainer)`
  border-top: 1px solid ${colors.border.dark};
  display: flex;
  width: 100%;
  ${respondTo.md`
    display: none;
  `}
`;
const FooterLogo = styled.div`
  max-width: 132px;
  padding-top: 1px;
  padding-right: 24px;
`;

const StyledImage = styled.img`
  max-width: 132px;
`;
const FooterCopyright = styled.div`
  padding-top: 8px;
  color: ${colors.text.accent};
  font-size: 1rem;
  font-weight: 600;
`;
const FooterLinksContainer = styled.div`
  padding: 24px 0 24px 0;
  border-left: 1px solid ${colors.border.dark};
  border-left-width: 0px;
  ${respondTo.md`
    padding: 32px;
    border-left-width: 1px;
  `}
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${respondTo.xl`
    justify-content: space-around;
  `}
  width: 100%;
`;
const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  ${respondTo.md`
    width: 33%;
    padding-bottom: 0;
  `}
  ${respondTo.xl`
    width: auto;
  `}
`;
const FooterSectionHeader = styled.div`
  color: #9fb1bd;
  padding-bottom: 10px;
`;
const LinkStyle = css`
  color: ${colors.text.light};
  padding-bottom: 10px;
`;
const FooterSectionLink = styled(Link)`
  ${LinkStyle};
`;
const FooterSectionLinkExternal = styled.a`
  ${LinkStyle};
  display: flex;
  flex-direction: row;
`;
const DisclaimerBackground = styled.div`
  background-color: ${colors.brand.primary};
  border-top: 1px solid ${colors.border.dark};
`;
const FooterLinks = styled(FlexRow)``;
const DisclaimerTextContainer = styled(Container)`
  padding-top: 32px !important;
  padding-bottom: 48px !important;
`;
const DisclaimerText = styled.div`
  color: ${colors.text.accentlight};
  font-size: 0.75rem;
`;
const IconContainer = styled.div`
  padding: 0 0 4px 4px;
  display: flex;
  align-items: flex-end;
`;

export const MarqueeFooterFragment = graphql`
  fragment MarqueeFooter on Craft_footer_footer_Entry {
    id
    title
    slug
    dateUpdated
    sourceId

    ... on Craft_footer_footer_Entry {
      logo {
        id
        uid
        path
        filename
        extension
        title
        mimeType
        url
      }
      # logo {
      #   ...Asset
      # }

      footerSections {
        ... on Craft_footerSections_footerSections_Entry {
          id
          title
          footerLinks {
            ... on Craft_footerLinks_link_BlockType {
              id
              label
              linkUrl
              audience
              isExternal
            }
          }
        }
      }
    }
  }
`;

export const MarqueeDisclaimerFragment = graphql`
  fragment MarqueeDisclaimer on Craft_disclaimers_disclaimers_Entry {
    id
    editor
    dateUpdated
  }
`;

interface CraftData {
  craft: {
    footer: MarqueeFooter;
    disclaimer: MarqueeDisclaimer;
    footerDrafts: [MarqueeFooter];
    disclaimerDrafts: [MarqueeDisclaimer];
  };
}

const Footer: React.FC = () => {
  const { craft }: CraftData = useStaticQuery(graphql`
    query Footer {
      craft {
        footer: entry(type: "footer", slug: "marquee") {
          ...MarqueeFooter
        }

        footerDrafts: entries(type: "footer", slug: "marquee", drafts: true) {
          ...MarqueeFooter
        }

        disclaimer: entry(
          type: "disclaimers"
          slug: "marquee-site-disclaimer"
        ) {
          ...MarqueeDisclaimer
        }

        disclaimerDrafts: entries(
          type: "disclaimers"
          slug: "marquee-site-disclaimer"
          drafts: true
        ) {
          ...MarqueeDisclaimer
        }
      }
    }
  `);

  let { footer, footerDrafts, disclaimer, disclaimerDrafts } = craft;

  if (isQA && footerDrafts.length) {
    footer = getMostRecentDraft(footerDrafts);
  }

  if (isQA && disclaimerDrafts.length) {
    disclaimer = getMostRecentDraft(disclaimerDrafts);
  }
  return (
    <>
      <Background>
        <div>
          <FooterContainer>
            <FooterLinks>
              <FooterLogoContainer>
                <FooterLogo>
                  <StyledImage src={GSLOGO} alt="footer logo" />
                </FooterLogo>
                <FooterCopyright> &copy; 2023 Goldman Sachs</FooterCopyright>
              </FooterLogoContainer>
              <FooterLinksContainer>
                {footer.footerSections?.map((s: any) => {
                  const section = s as FooterSectionsEntry;
                  if (section && section.id) {
                    return (
                      <FooterSection key={section.id}>
                        <FooterSectionHeader>
                          {section.title?.toUpperCase()}
                        </FooterSectionHeader>
                        {section.footerLinks?.map(
                          (link: FooterSectionsLinks | null) => {
                            if (link && link.id && link.linkUrl) {
                              if (!isCorrectAudience(link.audience))
                                return null;

                              if (link.isExternal) {
                                return (
                                  <FooterSectionLinkExternal
                                    key={link.id}
                                    href={link.linkUrl}
                                    target="_blank"
                                  >
                                    {link.label}
                                    <IconContainer>
                                      <ExitToApp fontSize="inherit" />
                                    </IconContainer>
                                  </FooterSectionLinkExternal>
                                );
                              }

                              return (
                                <FooterSectionLink
                                  key={link.id}
                                  to={link.linkUrl}
                                >
                                  {link.label}
                                </FooterSectionLink>
                              );
                            }
                            return null;
                          },
                        )}
                      </FooterSection>
                    );
                  }
                  return null;
                })}
              </FooterLinksContainer>
            </FooterLinks>
          </FooterContainer>
          <MobileFooterLogoContainer>
            <FooterContainer>
              <FooterLogo>
                <IMG src={GSLOGO} alt="marqueeLogo" />
              </FooterLogo>
              <FooterCopyright> &copy; 2023 Goldman Sachs</FooterCopyright>
            </FooterContainer>
          </MobileFooterLogoContainer>
          <DisclaimerBackground>
            <DisclaimerTextContainer>
              <DisclaimerText
                dangerouslySetInnerHTML={{
                  __html: disclaimer.editor || '',
                }}
              />
            </DisclaimerTextContainer>
          </DisclaimerBackground>
        </div>
      </Background>
    </>
  );
};

export default Footer;
