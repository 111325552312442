import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

import { colors } from '../styles/variables';
import { QuestionsBlock } from '@mq/generated-types/dist/QuestionsBlock';
import { FlexRow } from '../styles/Library';
import { respondTo } from '../styles/mixins';

const QuestionText = styled.div`
  color: #f5f8f9;
  font-size: 1rem;
  font-family: GoldmanSansBold;
`;
const AnswerText = styled(QuestionText)`
  padding-top: 24px;
  font-family: GoldmanSans;
`;
const Content = styled.div`
  width: 100%;
  ${respondTo.md`
    width: 80%;
  `}
`;
const IconContainer = styled.div`
  font-size: 1.4rem;
  height: 24px;
`;
interface QuestionBlockI {
  isSelected: boolean;
}
const QuestionBlock = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
  padding: 32px;
  border: 1px solid ${colors.border.componentBlue};
  &:not(:last-child) {
    border: 1px solid ${colors.border.componentBlue};
    border-bottom: none;
  }
  background-color: ${({ isSelected }: QuestionBlockI) =>
    isSelected ? '#103A75' : '#0D4EA6'};
  :hover {
    cursor: pointer;
    background-color: #103a75;
  }
`;

interface QuestionsI {
  // This broke when extending GS Aurora TS config
  // questionList: QuestionsBlock['questions'];
  questionList: any;
}

const QuestionTable: React.FC<QuestionsI> = ({ questionList }: QuestionsI) => {
  if (!questionList) return null;
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const clearSelection = () => setSelectedQuestion('');
  const isSelected = (questionId: string | null) =>
    questionId === selectedQuestion;
  return (
    <Content>
      {questionList[0] &&
      'questions' in questionList[0] &&
      questionList[0].questions
        ? questionList[0]?.questions?.map((question) =>
            question ? (
              <QuestionBlock
                key={question.id || ''}
                isSelected={isSelected(question.id)}
                onClick={() =>
                  isSelected(question.id)
                    ? clearSelection()
                    : setSelectedQuestion(question.id || '')
                }
              >
                <div>
                  <QuestionText>{question.question}</QuestionText>
                  {isSelected(question.id) && (
                    <AnswerText>{question.answer}</AnswerText>
                  )}
                </div>
                <IconContainer>
                  {isSelected(question.id) ? (
                    <ArrowUpward fontSize="inherit" />
                  ) : (
                    <ArrowDownward fontSize="inherit" />
                  )}
                </IconContainer>
              </QuestionBlock>
            ) : null,
          )
        : null}
    </Content>
  );
};

export default QuestionTable;
