import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Library, styles } from '@mq/gatsby-components';

import {
  MarqueeNavigation_navigation_subNavigationMenu_Craft_navigationItems_navigationItems_Entry as NavItems,
  MarqueeNavigation_navigation_subNavigationMenu_Craft_navigationItems_navigationItems_Entry_subNavigationMenu as SubMenu,
} from '@mq/generated-types/dist/MarqueeNavigation';

const { PrimaryOutlineButton, FlexRow, IconContainer, IMG } = Library;
const { heights, colors, widths } = styles.variables;
const { respondTo } = styles.mixins;

const ExpandedSubmenu = styled.div`
  top: ${heights.header}px;
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;
  z-index: 999;
  padding: 0;
  background-color: ${colors.brand.secondary};
  width: 100%;
  max-width: ${widths.xxl}px;
  display: none;
  ${respondTo.xl`
    display: inherit;
  `}
`;
const SubMenuHeaderContainer = styled.div`
  width: 50%;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
`;
const CTARow = styled(FlexRow)`
  align-items: center;
`;
const SubMenuHeaderTitle = styled.div`
  font-size: 2.875rem;
  font-weight: 600;
`;
const SubMenuHeaderCTA = styled.div`
  font-size: 1rem;
  padding-right: 16px;
`;
const SubMenuContent = styled.div`
  display: flex;
  flex-direction: row;
  border-top: none;
`;
const SubMenuList = styled.div`
  width: 50%;
  background-color: ${colors.brand.tertiary};
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${colors.brand.secondary};
  &.col {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
  }
`;
const SubMenuListItem = styled(Link)`
  min-height: 200px;
  width: 250px;
  padding: 24px;
  border-bottom: 1px solid ${colors.brand.secondary};
  border-right: 1px solid ${colors.brand.secondary};
  display: flex;
  &.row {
    flex-direction: column;
    width: 33.33%;
  }
  &.col {
    align-items: center;
    flex-direction: row;
    flex: 1;
    width: 100%;
  }
  :hover {
    background-color: ${colors.brand.primary};
    text-decoration: none;
  }
`;
const SubMenuTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0 0 0;
  &.col {
    padding: 16px 0 0 24px;
    align-items: center;
    justify-content: center;
  }
  justify-content: space-between;
`;
const SubMenuListItemHeader = styled.div`
  color: ${colors.text.light};
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 22px;
`;
const SubText = styled.div`
  color: ${colors.text.accentlight};
  font-size: 1rem;
`;
const Description = styled(SubText)`
  padding-bottom: 16px;
`;

interface SubheaderPropsI {
  submenu: NavItems[];
}

const SubHeader: React.FC<SubheaderPropsI> = ({ submenu }) =>
  submenu.length ? (
    <ExpandedSubmenu>
      {submenu.map(
        ({
          id,
          blockTitle,
          buttonPrompt,
          buttonLabel,
          subNavigationMenu,
          buttonLink,
        }) => {
          if (id && subNavigationMenu) {
            const orientation = subNavigationMenu.length >= 3 ? 'row' : 'col';
            return (
              <SubMenuContent key={id}>
                <SubMenuHeaderContainer>
                  <SubMenuHeaderTitle>{blockTitle}</SubMenuHeaderTitle>
                  <CTARow>
                    <SubMenuHeaderCTA>{buttonPrompt}</SubMenuHeaderCTA>
                    {buttonLabel && (
                      <PrimaryOutlineButton className="thin" to={buttonLink}>
                        {buttonLabel}
                      </PrimaryOutlineButton>
                    )}
                  </CTARow>
                </SubMenuHeaderContainer>
                <SubMenuList className={orientation}>
                  {subNavigationMenu.map((menuItem: SubMenu | null) => {
                    return menuItem &&
                      menuItem.id &&
                      menuItem.href &&
                      menuItem.icon &&
                      menuItem.blockTitle ? (
                      <SubMenuListItem
                        to={menuItem.href}
                        key={menuItem.id}
                        className={orientation}
                      >
                        {menuItem.icon.length && (
                          <IconContainer size="sm">
                            {menuItem.icon.map((i) =>
                              i && i.id && i.url && i.title ? (
                                <IMG key={i.id} src={i.url} alt={i.title} />
                              ) : null,
                            )}
                          </IconContainer>
                        )}
                        <SubMenuTextContainer className={orientation}>
                          <div>
                            <SubMenuListItemHeader>
                              {menuItem.blockTitle}
                            </SubMenuListItemHeader>
                            <Description>{menuItem.description}</Description>
                          </div>
                          <SubText>{menuItem.subText}</SubText>
                        </SubMenuTextContainer>
                      </SubMenuListItem>
                    ) : null;
                  })}
                </SubMenuList>
              </SubMenuContent>
            );
          }
          return null;
        },
      )}
    </ExpandedSubmenu>
  ) : null;

export default SubHeader;
