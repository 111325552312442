import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../../styles/variables';
import {
  CenteredFlex,
  SectionSubTextLight,
  SectionTextLight,
} from '../../styles/Library';
import { respondTo } from '../../styles/mixins';
import PageComponent from '../PageComponent';
import Container from '../Container';
import { PostMetadata } from '../PostMetadata';

const Border = styled.div`
  width: 24px;
  height: 2px;
  background-color: ${colors.blue};
  margin: 9px 0px;
`;
const Background = styled.div`
  background-color: ${colors.brand.dark};
`;
const MetaContainer = styled(Container)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`;
const HeaderContainer = styled.div`
  padding: 0px 21px;
  ${respondTo.md`
    padding: 0px 112px;
  `}
`;

interface Props {
  post: {
    title: string | null;
    description: string | null;
    postDate: string | null;
    authors: {
      fullName: string | null;
    }[];
  };
}

export const PostHeader = ({ post }: Props) => {
  const { authors, description, postDate, title } = post;
  return (
    <Background>
      <PageComponent
        backgroundColor={colors.brand.dark}
        borderColor={colors.brand.dark}
      >
        <CenteredFlex>
          <HeaderContainer>
            <SectionTextLight>{title}</SectionTextLight>
            <Border />
            <SectionSubTextLight>{description}</SectionSubTextLight>
          </HeaderContainer>
        </CenteredFlex>
      </PageComponent>
      <MetaContainer>
        <PostMetadata authors={authors} postDate={postDate} darkTheme />
      </MetaContainer>
    </Background>
  );
};
