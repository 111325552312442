import React from 'react';
import styled from '@emotion/styled';
import { Photo } from '@material-ui/icons';

import { CenteredFlex } from '../styles/Library';
import { colors } from '../styles/variables';

interface PlaceholderI {
  backgroundColor?: string;
  color?: string;
  fontSize?: string;
}

const Container = styled(CenteredFlex)`
  min-height: 250px;
  min-height: 416px;
  background-color: ${({ backgroundColor }: PlaceholderI) =>
    backgroundColor || colors.brand.primary};
  font-size: ${({ fontSize }: PlaceholderI) => fontSize || '3rem'};
  color: ${({ color }: PlaceholderI) => color || colors.brand.accent};
`;

const Placeholder: React.FC<PlaceholderI> = ({
  backgroundColor,
  color,
  fontSize,
}: PlaceholderI) => (
  <Container
    backgroundColor={backgroundColor}
    color={color}
    fontSize={fontSize}
  >
    <Photo fontSize="inherit" />
  </Container>
);

export default Placeholder;
