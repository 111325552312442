import React, { createContext } from 'react';
import { useProfile, IUserInfo } from '../hooks/use-profile';
export const ProfileContext = createContext<IUserInfo>({
  profile: undefined,
  hasLoaded: true,
  loggedIn: false,
});

export const ProfileContextProvider: React.FC = ({ children }) => {
  const profile = useProfile();
  return (
    <ProfileContext.Provider value={profile}>
      {children}
    </ProfileContext.Provider>
  );
};
