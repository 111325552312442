import React from 'react';
import styled from '@emotion/styled';

import CardGalleryItem from './CardGalleryItem';
import { CardGalleryBlock_cards_Craft_cardGallery_cardGallery_Entry as Card } from '@mq/generated-types/dist/CardGalleryBlock';
import { CollapseRow } from '../styles/Library';

const Content = styled(CollapseRow)`
  width: 100%;
  flex-wrap: wrap;
`;
interface CardGalleryProps {
  list: Card[];
}
const generateWideIndexes = (length: number) => {
  // TODO: write tests for this.
  const indexes = [0];
  for (let i = 3; i < length; i += 4) {
    indexes.push(i);
    indexes.push(i + 1);
  }
  return indexes;
};
export const isWide = (index: number, length: number) => {
  const wideIndexes = generateWideIndexes(length);
  return wideIndexes.includes(index);
};
const CardGalleryList: React.FC<CardGalleryProps> = ({
  list,
}: CardGalleryProps) => {
  return (
    <Content>
      {list?.map(
        (item, index) =>
          item && (
            <CardGalleryItem
              card={item}
              key={String(item.title)}
              isWide={isWide(index, list.length)}
            />
          ),
      )}
    </Content>
  );
};

export default CardGalleryList;
