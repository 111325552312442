import {
  IProfile,
  Env,
  initializeGA,
  disposeGA,
} from '@mq/aurora-launcher-core';

/**
 * Initializes Google Analytics from Aurora Launcher.
 * @param {boolean} [debug] Prints debug logs to the console. Requires use of localhost.gs.com to function.
 */

export const initializeAnalytics = (debug?: boolean): void => {
  const { host } = window.location;

  // env is preview if host is ~localhost.gs, prod if host is ~marquee.gs.com, qa otherwise
  const env = host.includes('localhost.gs')
    ? Env.preview
    : host.includes('marquee.gs.com')
    ? Env.prod
    : Env.qa;

  initializeGA({} as IProfile, env, debug);
};

export const destroyAnalytics = (): void => {
  disposeGA();
};
