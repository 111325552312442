import React from 'react';
import styled from '@emotion/styled';

import PageComponent from '../PageComponent';
import CardTwoColumnList from '../CardTwoColumnList';
import { SectionTextDark, SectionSubTextDark } from '../../styles/Library';
import { respondTo } from '../../styles/mixins';
import { CardTwoColumnBlock as CardTwoColumn } from '@mq/generated-types/dist/CardTwoColumnBlock';

interface CardGalleryI {
  craft: CardTwoColumn;
}

const TextContainer = styled.div`
  ${respondTo.md`
    padding-bottom: 12px;
  `}
`;

const CardTwoColumnBlock: React.FC<CardGalleryI> = ({
  craft,
}: CardGalleryI) => {
  if (!craft) return null;
  const { blockTitle, description, cards } = craft;
  return (
    <PageComponent>
      {(blockTitle || description) && (
        <TextContainer>
          <SectionTextDark>{blockTitle}</SectionTextDark>
          <SectionSubTextDark>{description}</SectionSubTextDark>
        </TextContainer>
      )}
      <CardTwoColumnList list={cards} />
    </PageComponent>
  );
};

export default CardTwoColumnBlock;
