// marketing site.
import CardGallery from './components/blocks/CardGallery';
import CardTwoColumn from './components/blocks/CardTwoColumn';
import CardThreeColumn from './components/blocks/CardThreeColumn';
import Collateral from './components/blocks/Collateral';
import { Contact } from './components/blocks/Contact';
import CTA from './components/blocks/CTA';
import FeatureOverUnder from './components/blocks/FeatureOverUnder';
import FeatureSideBySide from './components/blocks/FeatureSideBySide';
import Files from './components/blocks/Files';
import Grid from './components/blocks/Grid';
import IntroHome from './components/IntroHomeBlock';
import IntroPage from './components/blocks/IntroPage';
import IntroSection from './components/blocks/IntroSection';
import NavigationSub from './components/blocks/NavigationSub';
import ProductInfo from './components/blocks/ProductInfo';
import Questions from './components/blocks/Questions';
import Stats from './components/blocks/Stats';
import TabbedHorizontal from './components/blocks/TabbedHorizontal';
import TabbedVertical from './components/blocks/TabbedVertical';
import Table from './components/blocks/Table';
import Teaser from './components/blocks/Teaser';
import Video from './components/blocks/Video';
import QABanner from './components/QABanner';
// devblog.
import { LandingHeader } from './components/devblogBlocks/LandingHeader';
import { LandingList } from './components/devblogBlocks/LandingList';
import { PostBody } from './components/devblogBlocks/PostBody';
import { PostHeader } from './components/devblogBlocks/PostHeader';
// layout
import Container from './components/Container';
import LayoutMain from './components/LayoutMain';
import LayoutRoot from './components/LayoutRoot';
import Page from './components/Page';
import PageComponent from './components/PageComponent';
// styles
import * as variables from './styles/variables';
import * as mixins from './styles/mixins';
import * as Library from './styles/Library';

const styles = {
  variables,
  mixins,
};

// hooks
import { useQAAudience } from './hooks/useQAAudience';

export {
  CardGallery,
  CardThreeColumn,
  CardTwoColumn,
  Collateral,
  Container,
  Contact,
  CTA,
  FeatureOverUnder,
  FeatureSideBySide,
  Files,
  Grid,
  IntroHome,
  IntroPage,
  IntroSection,
  NavigationSub,
  LandingHeader,
  LandingList,
  LayoutMain,
  LayoutRoot,
  Library,
  Page,
  PageComponent,
  PostBody,
  PostHeader,
  ProductInfo,
  Questions,
  Stats,
  TabbedHorizontal,
  TabbedVertical,
  Table,
  Teaser,
  Video,
  styles,
  QABanner,
  useQAAudience,
};
