import React from 'react';
import styled from '@emotion/styled';

import Container from './Container';
import { CollapseRow } from '../styles/Library';
import { respondTo } from '../styles/mixins';
import { colors } from '../styles/variables';
import { formatPostDate, getAllAuthors } from './utils';

const TextRow = styled(CollapseRow)`
  justify-content: space-between;
`;
interface ContainerProps {
  darkTheme?: boolean;
}
const StyledContainer = styled(Container)`
  border: 1px solid red;
  padding: 24px !important;
  background-color: ${({ darkTheme }: ContainerProps) =>
    darkTheme ? colors.brand.secondary : colors.white};
  color: ${({ darkTheme }: ContainerProps) =>
    darkTheme ? colors.text.light : colors.text.dark};
  border: 1px solid
    ${({ darkTheme }: ContainerProps) =>
      darkTheme ? colors.brand.secondary : colors.border.light};
  font-size: 1rem;
  ${respondTo.md`
    padding-left: 112px !important;
  `}
`;

interface Props {
  authors: {
    fullName: string | null;
  }[];
  postDate: string | null;
  darkTheme?: boolean;
}
export const PostMetadata = ({ authors, darkTheme, postDate }: Props) => {
  const formattedDate = formatPostDate(postDate);
  const allAuthors = getAllAuthors({ authors });
  return (
    <StyledContainer darkTheme={darkTheme}>
      <TextRow>
        <div>{`Authored by ${allAuthors.join(' & ')}`}</div>
        <div>{`Published  on ${formattedDate}`}</div>
      </TextRow>
    </StyledContainer>
  );
};
