import React from 'react';
import styled from '@emotion/styled';

import Container from '../Container';
import { colors } from '../../styles/variables';
import {
  PageComponentText,
  SectionTextDark,
  SectionSubTextDark,
} from '../../styles/Library';
import { respondTo } from '../../styles/mixins';
import { TableBlock } from '@mq/generated-types/dist/TableBlock';

const Tables = styled.div`
  display: flex;
  flex-direction: column;
  ${respondTo.md`
    flex-direction: row;
  `}
`;
const TableContainer = styled.div`
  padding: 0 0 32px 0;
  width: 100%;
  ${respondTo.md`
    width: 640px;
    padding: 0 32px 0 0;
  `}
  &:last-child {
    padding: 0;
  }
`;
const TableHeader = styled.div`
  width: 100%;
  padding: 16px 24px;
  font-size: 1.2rem;
  ${respondTo.xl`
    font-size: 1.38rem;
  `}
  background-color: ${colors.brand.primary};
  color: ${colors.text.light};
`;
const TableItem = styled.div`
  padding: 16px;
  &.offer {
    background-color: ${colors.white};
  }
  &.offer:not(:last-child) {
    border-bottom: 1px solid ${colors.text.accentlight};
  }
`;
const StyledTable = styled.table`
  font-size: 1rem;
  border: 1px solid ${colors.text.accentlight};
  border-top: none;
  margin: 0px;
  :last-of-type {
    border-top: none;
  }
  td {
    border: 1px solid ${colors.text.accentlight};
    border-top: none;
    &.key {
      border-right: 1px solid ${colors.text.accentlight};
    }
    &.value {
      border-left: 1px solid ${colors.text.accentlight};
    }
    padding: 0;
    width: 50%;
    vertical-align: top;
  }
  td:nth-of-type(odd) {
    background-color: #f2f5f7;
    font-weight: 600;
  }
  .offer {
    color: ${colors.text.brand};
    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }
`;
const Footnote = styled.div``;

interface CraftResponseI {
  craft: TableBlock;
}

const Table: React.FC<CraftResponseI> = ({ craft }: CraftResponseI) => {
  const { blockTitle, description, tables } = craft;
  return (
    <Container>
      {(blockTitle || description) && (
        <PageComponentText>
          <SectionTextDark>{blockTitle}</SectionTextDark>
          <SectionSubTextDark>{description}</SectionSubTextDark>
        </PageComponentText>
      )}
      <Tables>
        {tables?.map((table) => {
          if (!table) return null;
          const tableId = ('id' in table && table.id) || '';
          const tableTitle = 'title' in table && table.title;
          const tableItems = ('tableItem' in table && table.tableItem) || [];
          const tableFootnote = 'footnoteText' in table && table.footnoteText;
          return (
            <TableContainer key={tableId}>
              <TableHeader>{tableTitle}</TableHeader>
              {tableItems.map((tableItemSection) =>
                tableItemSection ? (
                  <StyledTable key={tableItemSection.section || ''}>
                    <tbody>
                      <tr>
                        <td className="key">
                          <TableItem>{tableItemSection?.section}</TableItem>
                        </td>
                        <td className="value">
                          {tableItemSection?.values
                            ?.split('\r\n')
                            .map((offer) => (
                              <TableItem key={offer} className="offer">
                                {offer}
                              </TableItem>
                            ))}
                        </td>
                      </tr>
                    </tbody>
                  </StyledTable>
                ) : null,
              )}
              <Footnote>{tableFootnote}</Footnote>
            </TableContainer>
          );
        })}
      </Tables>
    </Container>
  );
};

export default Table;
