import React from 'react';
import styled from '@emotion/styled';
import { ArrowForward } from '@material-ui/icons';

import SectionCTAFooter from './SectionCTAFooter';
import { colors } from '../styles/variables';
import { SectionSubTextDark, SubComponentSubTextDark } from '../styles/Library';
import { respondTo } from '../styles/mixins';
import { CardThreeColumnBlock_cards_Craft_cardThreeColumn_cardThreeColumn_Entry as CardI } from '@mq/generated-types/dist/CardThreeColumnBlock';

const Card = styled.div`
  width: 100%;
  ${respondTo.md`
    width: 30.4%;
  `};
  ${respondTo.xl`
    width: 31.5%;
  `}
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .dark {
    background-color: ${colors.brand.secondary};
    border: 1px solid ${colors.border.dark};
    color: ${colors.text.accentlight};
    .title {
      color: ${colors.text.light};
    }
    .subtitle {
      color: ${colors.text.accentlight};
    }
    .cta {
      color: ${colors.text.ctaDark};
    }
    .icon {
      color: ${colors.text.ctaDark};
    }
  }
  .light {
    .title {
      color: ${colors.text.dark};
    }
    .subtitle {
      color: ${colors.text.accent};
    }
    .cta {
      color: ${colors.blue};
    }
    .icon {
      color: ${colors.blue};
    }
    background-color: ${colors.offwhite};
    border: 1px solid ${colors.border.light};
    color: ${colors.text.dark};
  }
  :not(:nth-of-type(3n - 2)) {
    ${respondTo.md`
      margin-left: 32px;
    `};
  }
`;

const CardTitle = styled(SectionSubTextDark)`
  font-weight: 600;
  padding-bottom: 8px;
`;
const CardSubTitle = styled(SubComponentSubTextDark)`
  padding-bottom: 8px;
`;
const TextSection = styled.div`
  padding: 32px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;
const CTAText = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;
const IconContainer = styled.div`
  color: ${colors.blue};
  font-size: 1.38rem;
`;

interface ThemedCardItemI extends CardI {
  isDarkTheme: boolean;
}

const CardThreeColumnItem: React.FC<ThemedCardItemI> = (
  item: ThemedCardItemI,
) => {
  const { isDarkTheme } = item;
  return (
    <Card className={isDarkTheme ? 'dark' : 'light'}>
      <TextSection className={isDarkTheme ? 'dark' : 'light'}>
        <CardTitle className="title">{item.title}</CardTitle>
        <CardSubTitle className="subtitle">{item.description}</CardSubTitle>
      </TextSection>
      <SectionCTAFooter
        backgroundColor={isDarkTheme ? '#3d5463' : colors.offwhite}
        ctaLink={item.buttonLink || ''}
        className={isDarkTheme ? 'dark' : 'light'}
      >
        <CTAText className="cta">{item.buttonLabel}</CTAText>
        <IconContainer>
          <ArrowForward className="icon" fontSize="inherit" />
        </IconContainer>
      </SectionCTAFooter>
    </Card>
  );
};

export default CardThreeColumnItem;
