import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { colors } from '../styles/variables';

interface StyleProps {
  backgroundcolor: string | undefined;
}

const CTAStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  padding: 16px 24px;
`;

const CTAContainer = styled.a`
  ${CTAStyle};
  background-color: ${({ backgroundcolor }: StyleProps) =>
    backgroundcolor || colors.offwhite};
  :hover {
    cursor: pointer;
    text-decoration: none;
    background-color: ${({ backgroundcolor }: StyleProps) =>
      backgroundcolor === colors.blue ? colors.blueaccent : colors.gray.dark};
  }
`;

const CTAContainerLink = styled(Link)`
  ${CTAStyle};
  background-color: ${({ backgroundcolor }: StyleProps) =>
    backgroundcolor || colors.offwhite};
  :hover {
    cursor: pointer;
    text-decoration: none;
    background-color: ${({ backgroundcolor }: StyleProps) =>
      backgroundcolor === colors.blue ? colors.blueaccent : colors.gray.dark};
  }
`;

interface Props {
  backgroundColor?: string;
  ctaLink: string;
  children: React.ReactNode;
  className?: string;
}
const CTAFooter: React.FC<Props> = ({
  backgroundColor,
  className,
  ctaLink,
  children,
}: Props) => {
  var re = new RegExp('^(http|https)://', 'i');
  const external = re.test(ctaLink);
  if (external) {
    return (
      <CTAContainer
        target="_blank"
        className={className || ''}
        backgroundcolor={backgroundColor}
        href={ctaLink}
      >
        {children}
      </CTAContainer>
    );
  }

  return (
    <CTAContainerLink
      to={ctaLink}
      className={className || ''}
      backgroundcolor={backgroundColor}
    >
      {children}
    </CTAContainerLink>
  );
};

export default CTAFooter;
