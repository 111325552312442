import * as React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { LayoutMain, LayoutRoot, QABanner } from '@mq/gatsby-components';

import 'modern-normalize';
import '../styles/fonts.css';
import '../styles/normalize';

import { ProfileContextProvider } from '../components/ProfileContext';
import NavMain from '../components/NavMain';
import Footer from '../components/Footer';
import { isQA } from '../utils/misc';
import favicon from '../../static/marquee_assets/favicon.ico';

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
    };
  };
}

const IndexLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <ProfileContextProvider>
        <LayoutRoot>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: data.site.siteMetadata.description,
              },
              { name: 'keywords', content: data.site.siteMetadata.keywords },
            ]}
            link={[{ rel: 'icon', href: `${favicon}` }]}
          />
          {isQA && <QABanner />}
          <NavMain />
          <LayoutMain>{children}</LayoutMain>
          <Footer />
        </LayoutRoot>
      </ProfileContextProvider>
    )}
  />
);

export default IndexLayout;
