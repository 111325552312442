import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ArrowDownward } from '@material-ui/icons';

import { colors } from '../../styles/variables';
import { CenteredFlex } from '../../styles/Library';
import { LandingListItem } from '../LandingListItem';
import PageComponent from '../PageComponent';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const MoreButton = styled.div`
  background-color: ${colors.offwhite};
  color: ${colors.blue};
  padding: 14px;
  border: 1px solid blue;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
`;
const ButtonWrapper = styled.div`
  margin: 40px;
  &:hover {
    cursor: pointer;
  }
`;
const Text = styled.div`
  margin-right: 8px;
`;
const Arrow = styled(ArrowDownward)`
  margin-top: 3px;
`;

export interface BlogPost {
  id: string | null;
  title: string | null;
  editor?: string | null;
  description: string | null;
  postDate: string | null;
  slug: string | null;
  authors: {
    fullName: string | null;
  }[];
}
interface Props {
  entries: BlogPost[];
}
export const LandingList = ({ entries }: Props) => {
  // the number of visible posts.
  const [numVisible, setNumVisible] = useState(4);
  const increaseNumVisible = () => setNumVisible(numVisible + 4);
  const showButton = numVisible < entries.length;
  return (
    <PageComponent backgroundColor={colors.offwhite}>
      <ListContainer>
        {entries.slice(0, numVisible).map((entry) => (
          <LandingListItem key={entry.id || ''} item={entry} />
        ))}
      </ListContainer>
      {showButton && (
        <CenteredFlex>
          <ButtonWrapper onClick={increaseNumVisible}>
            <MoreButton>
              <Text>Load More Posts</Text>
              <Arrow fontSize="inherit" />
            </MoreButton>
          </ButtonWrapper>
        </CenteredFlex>
      )}
    </PageComponent>
  );
};
