import { dimensions, breakpoints } from './variables';
import { injectStyles } from './utils';

export const getEmSize = (size: number) => size / dimensions.fontSize.regular;

export const respondTo = {
  xs: (styles: TemplateStringsArray) => injectStyles(breakpoints.xs, styles),
  sm: (styles: TemplateStringsArray) => injectStyles(breakpoints.sm, styles),
  md: (styles: TemplateStringsArray) => injectStyles(breakpoints.md, styles),
  lg: (styles: TemplateStringsArray) => injectStyles(breakpoints.lg, styles),
  xl: (styles: TemplateStringsArray) => injectStyles(breakpoints.xl, styles),
};
