import React from 'react';

import { colors } from '../../styles/variables';
import {
  CenteredFlex,
  SectionSubTextLight,
  SectionTextLight,
} from '../../styles/Library';
import PageComponent from '../PageComponent';

interface Props {
  title: string;
  description: string;
}

export const LandingHeader = ({ title, description }: Props) => {
  return (
    <PageComponent backgroundColor={colors.brand.dark}>
      <CenteredFlex>
        <div>
          <SectionTextLight>{title}</SectionTextLight>
          <SectionSubTextLight>{description}</SectionSubTextLight>
        </div>
      </CenteredFlex>
    </PageComponent>
  );
};
