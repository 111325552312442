import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { colors } from './variables';
import { respondTo } from './mixins';
import { getPixelVal } from './utils';

export const UL = styled.ul`
  list-style: none;
  padding-left: 0px;
`;

export const IMG = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const navLinkStyles = `
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.text.light};
  font-size: 1rem;
  padding: 0px 10px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
    background-color: ${colors.brand.secondary};
  }
`;
export const NavigationLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.text.light};
  font-size: 1rem;
  padding: 0px 10px;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &.no-padding-left {
    padding-left: 0px;
  }
  &:hover {
    background-color: ${colors.brand.secondary};
    &.icon {
      background-color: ${colors.brand.primary};
    }
  }
`;
export const NavigationButton = styled.button`
  ${navLinkStyles}
`;
export const NavigationExternalLink = styled.a`
  ${navLinkStyles}
  &:hover {
    text-decoration: none;
  }
`;

export const CenteredFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const CollapseRow = styled.div`
  display: flex;
  flex-direction: column;
  ${respondTo.md`
    flex-direction: row;
  `}
`;

export const ProductMetaItem = styled(CenteredFlex)`
  align-items: flex-start;
  width: 100%;
  border: 1px solid ${colors.border.light};
  border-bottom-width: 0.5px;
  border-top-width: 0px;
  &:first-of-type {
    border-top-width: 1px;
  }
  ${respondTo.md`
    width: 33.33%;
    border-top-width: 1px;
    :not(:last-child) {
      border-right: none;
    }
  `}
`;

const primaryButtonStyles = `
  background-color: ${colors.brand.primary};
  color: ${colors.white};
  padding: 8px 16px;
  font-size: 1rem;
  border-radius: 3px;
  text-decoration: none;
  max-height: 64px;
  white-space: nowrap;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &.thin {
    max-height: 44px;
    padding: 3px 16px;
  }
  &.thick {
    min-height: 50px;
  }
  &.margin-left {
    margin-left: 16px;
  }
`;
const secondaryButtonStyles = `
  background-color: ${colors.white};
  color: ${colors.brand.primary};
  white-space: nowrap;
  &:hover {
    background-color: ${colors.gray.dark};
  }
`;
const primaryOutlineButtonStyles = `
  border: 1px solid ${colors.white};
  background-color: inherit;
  &:hover {
    border: 1px solid ${colors.gray.dark};
    background-color: ${colors.gray.dark};
    color: ${colors.brand.primary};
  }
`;
export const PrimaryLink = styled.a`
  ${primaryButtonStyles}
`;
export const PrimaryButton = styled(Link)`
  ${primaryButtonStyles}
`;
export const SecondaryButton = styled(PrimaryButton)`
  ${secondaryButtonStyles}
`;
export const SecondaryLink = styled(PrimaryLink)`
  ${secondaryButtonStyles}
`;
export const PrimaryOutlineButton = styled(PrimaryButton)`
  ${primaryOutlineButtonStyles}
`;
export const PrimaryOutlineLink = styled(PrimaryLink)`
  ${primaryOutlineButtonStyles}
`;
export const BlueButton = styled(PrimaryButton)`
  background-color: ${colors.blue};
  &:hover {
    background-color: ${colors.blueaccent};
  }
`;
export const GrayButton = styled(PrimaryButton)`
  background-color: ${colors.gray.dark};
  color: ${colors.text.dark};
`;
export const BlueOutlineButton = styled(PrimaryButton)`
  background-color: ${colors.white};
  color: ${colors.brand.primary};
  border: 1px solid ${colors.blue};
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.blue};
  &:hover {
    background-color: ${colors.blueaccent};
    color: ${colors.white};
  }
`;
export const SecondaryOutlineButton = styled(SecondaryButton)`
  border: 1px solid ${colors.brand.primary};
`;

export const SectionTextLight = styled.div`
  font-size: 2rem;
  line-height: 2.5rem;
  ${respondTo.md`
    width: 70%;
  `}
  ${respondTo.xl`
  font-size: 3rem;
  line-height: 3.5rem;
`}
  color: ${colors.text.light};
  font-weight: 600;
  padding-bottom: 8px;
  &.full-width {
    width: 100%;
  }
`;
export const SectionTextDark = styled(SectionTextLight)`
  color: ${colors.text.dark};
`;
export const SectionSubTextLight = styled.div`
  font-size: 1.2rem;
  line-height: 1.7rem;
  ${respondTo.md`
    width: 70%;
  `}
  ${respondTo.xl`
    font-size: 1.38rem;
  `}
  color: ${colors.text.accentlight};
  &.full-width {
    width: 100%;
  }
`;
export const SectionSubTextDark = styled(SectionSubTextLight)`
  color: ${colors.text.accent};
`;
export const ComponentText = styled(SectionTextDark)`
  font-size: 2;
  ${respondTo.xl`
    font-size: 3rem;
  `}
`;
export const ComponentSubText = styled(SectionSubTextDark)`
  font-size: 1.2rem;
`;
export const ComponentSubTextLight = styled(ComponentSubText)`
  color: ${colors.text.accentlight};
`;
export const SubComponentTextDark = styled.div`
  font-size: 1.38rem;
  font-weight: 600;
  padding-bottom: 8px;
`;
export const SubComponentSubTextDark = styled.div`
  font-size: 1rem;
  color: ${colors.text.accent};
  padding-bottom: 32px;
`;
export const SubComponentSubTextLight = styled(SubComponentSubTextDark)`
  color: ${colors.text.accentlight};
`;
export const FPOPageHeader = styled.div`
  padding: 50px 0;
  background-color: #1c2b36;

  h1,
  h2 {
    color: #fff;
  }
`;
export const FPOAnchorLinks = styled.div`
  display: flex;
  background-color: #2a3f4d;
  border-top: 1px solid #c1ccd6;
  border-bottom: 1px solid #c1ccd6;
`;
export const FPOAnchorLink = styled.a`
  margin: 10px;
  color: #fff;
`;
export const BlueHighlightText = styled.span`
  background-color: ${colors.blue};
  padding: 4px;
  color: ${colors.text.light};
  font-size: 0.875rem;
`;
const sizeOptions = ['xs', 'sm', 'md', 'lg', 'xl', undefined] as const;
type SizeOptionTypes = typeof sizeOptions[number];
interface IconContainerProps {
  size?: SizeOptionTypes;
}
export const IconContainer = styled(CenteredFlex)`
  width: ${({ size }: IconContainerProps) => getPixelVal(size)}px;
  height: ${({ size }: IconContainerProps) => getPixelVal(size)}px;
  border-radius: ${({ size }: IconContainerProps) => getPixelVal(size) / 2}px;
  background-color: ${colors.lightblue};
  color: ${colors.blue};
  font-size: ${({ size }: IconContainerProps) => getPixelVal(size) - 8}px;
  padding: 4px;
`;
export const PageComponentText = styled.div`
  padding-bottom: 38px;
  ${respondTo.md`
    padding-bottom: 44px;
  `}
`;
