export const getStartingIndex = (arr: any[], selected: any) => {
  let startingIndex = 0;
  for (let i = 0; i < arr.length; i += 1) {
    if (selected === arr[i]) {
      startingIndex = i;
    }
  }
  return startingIndex;
};
export const getTargetIndex = (arr: any[], selected: any, prev?: boolean) => {
  const startingIndex = getStartingIndex(arr, selected);
  if (prev) {
    return startingIndex - 1 === -1 ? arr.length - 1 : startingIndex - 1;
  } else {
    return startingIndex + 1 === arr.length ? 0 : startingIndex + 1;
  }
};
import { FormStateI } from './ContactForm';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
const getMonth = (index: number) => months[index];
interface getAuthorsProps {
  authors: {
    fullName: string | null;
  }[];
}
export const getAllAuthors = ({ authors }: getAuthorsProps) => {
  const allAuthors = authors.map((author) => author.fullName);
  return allAuthors;
};

export const formatPostDate = (postDate: string | null) => {
  const date = new Date(postDate || '');
  const formattedDate = `${getMonth(
    date.getMonth(),
  )} ${date.getDay()}, ${date.getFullYear()}`;
  return formattedDate;
};

export const generateContactEmail = (formState: FormStateI) => {
  return [
    `mailto:gs-marquee-inquiry@ny.email.gs.com?`,
    `subject=I'm interested in learning more about Marquee`,
    `&body=`,
    `Hi there. I'm interested in learning more about the Marquee platform. Here is some information about myself and what I'd like to know:\n`,
    `${encodeURIComponent(
      formState.fullName ? `\n Name: ${formState.fullName}` : '',
    )}`,
    `${encodeURIComponent(
      formState.company ? `\n Company: ${formState.company}` : ``,
    )}`,
    `${encodeURIComponent(
      formState.jobFunction.length > 0
        ? `\n Job Functions: ${formState.jobFunction.join(', ')}`
        : '',
    )}`,
    `${encodeURIComponent(
      formState.otherJobFunction
        ? `\n Job Function (other): ${formState.otherJobFunction}`
        : '',
    )}`,
    `${encodeURIComponent(
      formState.jobTitle ? `\n Job Title: ${formState.jobTitle}` : '',
    )}`,
    `${encodeURIComponent(
      formState.interestedMarkets.length > 0
        ? `\n Interested Markets: ${formState.interestedMarkets.join(', ')}`
        : '',
    )}`,
    `${encodeURIComponent(
      formState.interestedOfferings.length > 0
        ? `\n Interested Services: ${formState.interestedOfferings.join(', ')}`
        : '',
    )}`,
    `${encodeURIComponent(
      formState.gsContact ? `\n GS Contact: ${formState.gsContact}` : '',
    )}`,
    `${encodeURIComponent(
      formState.additionalInfo
        ? `\n Additional Info: ${formState.additionalInfo}`
        : '',
    )}`,
  ].join('');
};
