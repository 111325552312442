import React from 'react';
import styled from '@emotion/styled';

import Container from '../Container';
import {
  CenteredFlex,
  CollapseRow,
  SectionSubTextLight,
  SectionTextLight,
} from '../../styles/Library';
import { respondTo } from '../../styles/mixins';
import { colors } from '../../styles/variables';

import {
  StatsBlock,
  StatsBlock_data,
} from '@mq/generated-types/dist/StatsBlock';

const Background = styled.div`
  background-color: ${colors.blue};
  border-bottom: 1px solid ${colors.blueaccent};
`;
const StyledContainer = styled(Container)`
  height: 100%;
  padding: 0;
  ${respondTo.md`
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  `}
`;
const StatItem = styled(CenteredFlex)`
  padding: 32px;
  ${respondTo.md`
    padding: 104px 8px;
  `}
  text-align: center;
  height: 100%;
  flex-direction: column;
  width: 100%;
  &:not(:last-child) {
    border-right: 1px solid ${colors.blueaccent};
    border-bottom: 1px solid ${colors.blueaccent};
    border-right-width: 0px;
    ${respondTo.md`
      border-right-width: 1px;
      border-bottom-width: 0px;
    `}
  }
`;

const StatHeader = styled(SectionTextLight)`
  font-size: 2rem;
  ${respondTo.xl`
    font-size: 3rem;
  `}
`;

interface CraftData {
  craft: any;
}

const Stats: React.FC<CraftData> = ({ craft }: CraftData) => (
  <Background>
    <StyledContainer>
      <CollapseRow>
        {craft.data.map((stat: any) =>
          stat ? (
            <StatItem key={`${JSON.stringify(stat)}`}>
              <StatHeader className="full-width">{stat.stat}</StatHeader>
              <SectionSubTextLight className="full-width">
                {stat.description}
              </SectionSubTextLight>
            </StatItem>
          ) : null,
        )}
      </CollapseRow>
    </StyledContainer>
  </Background>
);

export default Stats;
