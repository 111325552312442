import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { IMG } from '../styles/Library';
import { colors } from '../styles/variables';
import Container from './Container';

interface ComponentI {
  backgroundColor?: string;
  borderColor?: string;
  children?: ReactNode;
  backgroundImg?: any;
}
const Background = styled.div`
  border-bottom: 1px solid
    ${({ borderColor }: ComponentI) => borderColor || colors.brand.accent};
  background-color: ${({ backgroundColor }: ComponentI) =>
    backgroundColor || colors.white};
  position: relative;
`;
const BackgroundImage = styled(IMG)`
  position: absolute;
  z-index: 0;
`;

const PageComponent: React.FC<ComponentI> = ({
  backgroundColor,
  borderColor,
  children,
  backgroundImg,
}: ComponentI) => (
  <Background backgroundColor={backgroundColor} borderColor={borderColor}>
    {backgroundImg && backgroundImg.url && (
      <BackgroundImage
        src={backgroundImg?.url || ''}
        alt={backgroundImg?.title || ''}
      />
    )}
    <Container>{children}</Container>
  </Background>
);

export default PageComponent;
