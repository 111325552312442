import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { ArrowForward } from '@material-ui/icons';

import { BlogPost } from './devblogBlocks/LandingList';
import { colors } from '../styles/variables';
import { CenteredFlex, CollapseRow } from '../styles/Library';
import { respondTo } from '../styles/mixins';
import { formatPostDate, getAllAuthors } from './utils';

const Border = styled.div`
  width: 24px;
  height: 2px;
  background-color: ${colors.blue};
  margin: 9px 0px;
`;
const Container = styled(CollapseRow)`
  background-color: ${colors.white};
  border: 1px solid ${colors.border.light};
  &:not(:last-child) {
    margin-bottom: 24px;
    ${respondTo.md`
      margin-bottom: 40px;
    `}
    ${respondTo.xl`
      margin-bottom: 56px;
  `}
  }
`;
const Description = styled.div`
  padding: 16px;
  ${respondTo.md`
    padding: 24px;
  `}
  ${respondTo.xl`
    padding: 40px;
  `}
`;
const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 0px solid ${colors.border.light};
  border-top-width: 1px;
  ${respondTo.md`
    border-left-width: 1px;
    border-top-width: 0px;
    width: 322px;
  `}
`;
const Metadata = styled.div`
  padding: 16px;
`;
const BlogTitle = styled.div`
  font-size: 1.38rem;
  font-weight: 600;
  ${respondTo.md`
    font-size: 2rem;
  `}
  color: ${colors.brand.primary};
`;
const BlogDesc = styled(BlogTitle)`
  color: ${colors.text.accent};
  font-size: 1rem;
  font-weight: 400;
  ${respondTo.md`
    font-size: 1.38rem;
  `}
`;
const ReadButton = styled(CenteredFlex)`
  min-height: 48px;
  background-color: ${colors.blue};
  color: ${colors.text.light};
`;
const MetaTextDesc = styled.div`
  color: ${colors.text.accent};
  padding-bottom: 4px;
`;
const MetaText = styled.div`
  color: ${colors.brand.primary};
  &:not(:last-child) {
    padding-bottom: 16px;
  }
`;
const ButtonText = styled.div`
  padding-right: 8px;
`;

interface Props {
  item: BlogPost;
}
export const LandingListItem = ({ item }: Props) => {
  const { title, description, postDate, authors, slug } = item;
  const allAuthors = getAllAuthors({ authors });
  const formattedDate = formatPostDate(postDate);
  return (
    <Container>
      <Description>
        <BlogTitle>{title}</BlogTitle>
        <Border />
        <BlogDesc>{description}</BlogDesc>
      </Description>
      <MetadataContainer>
        <Metadata>
          <MetaTextDesc>Authored by</MetaTextDesc>
          <MetaText>{allAuthors.join(' & ')}</MetaText>
          <MetaTextDesc>Published on</MetaTextDesc>
          <MetaText>{formattedDate}</MetaText>
        </Metadata>
        {slug && (
          <Link to={slug}>
            <ReadButton>
              <ButtonText>Read Post</ButtonText>
              <ArrowForward />
            </ReadButton>
          </Link>
        )}
      </MetadataContainer>
    </Container>
  );
};
