import { useEffect, useState } from 'react';
import { IProfile } from '@mq/aurora-launcher-core';

// undefined if it's still loading, false if the user is not authorized, IProfile otherwise
// not authorized means any error from the server, not only 401s.
export interface IUserInfo {
  profile: IProfile;
  hasLoaded: boolean;
  loggedIn: boolean;
}

export function useProfile() {
  const [user, setUser] = useState<IUserInfo>({
    profile: undefined,
    hasLoaded: false,
    loggedIn: false,
  });
  useEffect(() => {
    fetch('/v1/users/self')
      .then((res) => (res.ok ? res.json() : false))
      .then((data) =>
        setUser({
          profile: data,
          hasLoaded: true,
          loggedIn: typeof data === 'object' && data !== null,
        }),
      )
      .catch((err) => {
        console.error('Fetch error - cannot get user', err);
        setUser({ profile: undefined, hasLoaded: true, loggedIn: false });
      });
  }, []);

  return user;
}
