import React, { useEffect } from 'react';
import { destroyAnalytics, initializeAnalytics } from '../shared/Analytics';

export const Analytics: React.FC = () => {
  useEffect(() => {
    // Initialize analytics on mount
    initializeAnalytics();
    // Destroy on unmount
    return destroyAnalytics;
  }, []);
  return null;
};
