import React from 'react';
import styled from '@emotion/styled';

import { ContactIntro } from '../ContactIntro';
import { ContactForm } from '../ContactForm';
import { colors } from '../../styles/variables';
import { getEmSize, respondTo } from '../../styles/mixins';

import { breakpoints } from '../../styles/variables';

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 80vh;
`;
const FormContainer = styled.div`
  background-color: ${colors.brand.primary};
  max-width: ${breakpoints.lg}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${respondTo.lg`
    flex-direction: row;
  `}
`;
const LeftBackground = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: ${colors.brand.primary};
`;
const RightBackground = styled(LeftBackground)`
  background-color: ${colors.white};
`;

interface Email {
  label: string | null;
  emailAddress: string | null;
}
interface Phone {
  label: string | null;
  phoneNumber: string | null;
}
interface ContactProps {
  contactList: (Phone | Email | null)[] | null;
}
export const Contact = ({ contactList }: ContactProps) => {
  return (
    <PageContainer>
      <LeftBackground />
      <FormContainer>
        <ContactIntro contactList={contactList} />
        <ContactForm />
      </FormContainer>
      <RightBackground />
    </PageContainer>
  );
};
