/* eslint-disable max-len */

export const colors = {
  brand: {
    primary: '#1c2b36',
    secondary: '#2A3F4D',
    tertiary: '#3d5463',
    accent: '#e1e7ec',
    dark: '#0D171F',
  },
  offwhite: '#F2F5F7',
  success: '#37b635',
  warning: '#ec1818',
  ui: {
    bright: '#e0d6eb',
    light: '#f5f3f7',
    whisper: '#fbfafc',
  },
  code: '#fcf6f0',
  gray: {
    dark: '#DCE3E8',
    copy: 'hsla(270, 15.797828016000002%, 0%, 0.88)',
    calm: 'rgba(0, 0, 0, 0.54)',
  },
  border: {
    light: '#e1e7ec',
    dark: '#374e5d',
    componentDark: '#3E5463',
    componentLight: '#e1e7ec',
    componentBlue: '#75b1ff',
  },
  white: '#fff',
  black: '#000',
  blue: '#186ade',
  lightblue: '#d4e4fa',
  blueaccent: '#0D4EA6',
  bluelink: '#75b1ff',
  text: {
    dark: '#333333',
    light: '#F8F9FA',
    accent: '#5B7282',
    brand: '#032348',
    accentlight: '#C1CCD6',
    ctaDark: '#adccf7',
  },
  yellow: '#faf6cf',
  yellowDark: '#f7e379',
};

export const fonts = {
  sansSerif:
    'GoldmanSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
  serif: 'Georgia, "Times New Roman", Times, serif',
  monospace:
    'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
};

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 800,
  lg: 1168,
  xl: 1168,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140,
  xxl: 1312,
};

export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18,
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25,
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2,
  },
  containerPadding: 1.5,
};

export const heights = {
  header: 60,
};
