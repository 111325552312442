import React from 'react';
import styled from '@emotion/styled';
import { CenteredFlex, IMG } from '../styles/Library';
import { colors } from '../styles/variables';
import { respondTo } from '../styles/mixins';

interface ContainerProps {
  backgroundColor: string;
}
const Container = styled(CenteredFlex)`
  width: 100%;
  ${respondTo.md`
    width: 50%;
  `}
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor};
  &.left {
    display: none;
    ${respondTo.md`
      display: inherit;
    `}
  }
  &.right {
    display: none;
    ${respondTo.md`
      display: inherit;
    `}
  }
  &.mobile {
    display: inherit;
    ${respondTo.md`
      display: none;
    `}
  }
`;
const Image = styled(IMG)`
  object-fit: contain;
`;
const ImageContainer = styled(CenteredFlex)`
  height: 100%;
  padding: 24px;
  ${respondTo.md`
    padding: 0px 24px;
  `}
`;

interface ImageBlockI {
  imgSrc?: string;
  imgAlt?: string;
  theme: string | null;
  className: string;
}
const InfoBlock: React.FC<ImageBlockI> = ({
  imgSrc,
  imgAlt,
  theme = 'blue',
  className,
}: ImageBlockI) => (
  <Container
    className={className}
    backgroundColor={theme === 'blue' ? colors.blue : colors.brand.primary}
  >
    <ImageContainer>
      <Image src={imgSrc} alt={imgAlt || ''} />
    </ImageContainer>
  </Container>
);

export default InfoBlock;
