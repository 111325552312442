/* eslint-disable max-len */
import React from 'react';
import styled from '@emotion/styled';
import { withPrefix } from 'gatsby';

import { colors } from '../../styles/variables';
import {
  IMG,
  SectionTextLight,
  SectionSubTextLight,
} from '../../styles/Library';
import Container from '../Container';
import { BlueButton } from '../../styles/Library';
import { respondTo } from '../../styles/mixins';
import { CTABlock } from '@mq/generated-types/dist/CTABlock';

interface BackgroundI {
  backgroundColor: string | null;
}
const Background = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ backgroundColor }: BackgroundI) =>
    backgroundColor || colors.brand.secondary};
  background-image: url(${withPrefix('/marquee_assets/MarqueeLogo.png')});
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: auto 284px;
  height: 273px;
  ${respondTo.md`
    height: 388px;
    background-image: none;
  `}
`;
const Title = styled(SectionTextLight)`
  line-height: 2.5rem;
  ${respondTo.md`
    line-height: 3.5rem;
  `}
`;
const StyledContainer = styled(Container)`
  display: flex;
  height: 100%;
`;
const CTAContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  padding-left: 0px;
  ${respondTo.md`
    padding-left: 305px;
  `}
  ${respondTo.xl`
    padding-left: 405px;
  `}
  justify-content: center;
`;
const CTAButton = styled.div`
  padding-top: 32px;
`;
const LogoContainer = styled.div`
  position: relative;
  width: 1400px;
  ${respondTo.md`
    width: 1700px;
  `}
  overflow: hidden;
`;
const MarqueeLogo = styled(IMG)`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 258px;
  width: auto;
  display: none;
  ${respondTo.md`
    display: inherit;
  `}
`;
interface CraftResponseI {
  craft: CTABlock;
}

const CTA: React.FC<CraftResponseI> = ({ craft }: CraftResponseI) => {
  const { blockTitle, description, buttonLabel, buttonLink } = craft;
  return (
    <Background backgroundColor={null}>
      <LogoContainer>
        <MarqueeLogo src={withPrefix('/marquee_assets/MarqueeLogo.png')} />
        <StyledContainer>
          <CTAContent>
            <Title className="full-width">{blockTitle}</Title>
            <SectionSubTextLight className="full-width">
              {description}
            </SectionSubTextLight>
            <CTAButton>
              {buttonLink && buttonLabel && (
                <BlueButton to={buttonLink}>{buttonLabel}</BlueButton>
              )}
            </CTAButton>
          </CTAContent>
        </StyledContainer>
      </LogoContainer>
    </Background>
  );
};

export default CTA;
