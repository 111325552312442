import React from 'react';
import styled from '@emotion/styled';

import PageComponent from '../PageComponent';
import TabbedVerticalList from '../TabbedVerticalList';
import {
  PageComponentText,
  SectionTextLight,
  SectionSubTextLight,
} from '../../styles/Library';
import { colors } from '../../styles/variables';
import {
  TabbedVerticalBlock,
  TabbedVerticalBlock_tabs_Craft_verticalTabs_verticalTabs_Entry as Tabs,
  TabbedVerticalBlock_tabs_Craft_verticalTabs_verticalTabs_Entry_verticalTabs as VerticalTabs,
} from '@mq/generated-types/dist/TabbedVerticalBlock';

const Content = styled.div`
  color: ${colors.text.light};
`;

interface CraftData {
  block: TabbedVerticalBlock;
}

const TabbedVertical: React.FC<CraftData> = ({ block }: CraftData) => {
  const { blockTitle, description, buttonLabel, buttonLink, buttonIcon, tabs } =
    block;

  const typedTabs = tabs as Tabs[];

  return (
    <PageComponent
      backgroundColor={colors.brand.primary}
      borderColor={colors.border.componentDark}
    >
      <Content>
        {(blockTitle || description) && (
          <PageComponentText>
            <SectionTextLight>{blockTitle}</SectionTextLight>
            <SectionSubTextLight>{description}</SectionSubTextLight>
          </PageComponentText>
        )}
        {typedTabs[0].verticalTabs && (
          <TabbedVerticalList
            buttonLabel={buttonLabel}
            buttonLink={buttonLink}
            buttonIcon={buttonIcon}
            tabs={typedTabs[0].verticalTabs as VerticalTabs[]}
          />
        )}
      </Content>
    </PageComponent>
  );
};

export default TabbedVertical;
