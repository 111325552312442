import React, { useState } from 'react';
import styled from '@emotion/styled';

import { colors } from '../styles/variables';
import { respondTo } from '../styles/mixins';
import { FlexRow, SectionSubTextLight } from '../styles/Library';

const Background = styled.div`
  background-color: ${colors.brand.primary};
  height: 100%;
  padding: 40px 24px 40px 24px;
  width: 100%;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${respondTo.xl`
    padding: 104px 80px 104px 0px;
    width: 50%;
  `}
`;

const Title = styled.div`
  display: flex;
  color: ${colors.text.light};
  font-weight: 600;
  font-size: 2rem;
  ${respondTo.md`
    font-size: 3rem;
  `}
  ${respondTo.xl`
    font-size: 4rem;
    line-height: 60px;
    padding-bottom: 8px;
  `}
`;
const Dropdown = styled.div`
  background-color: ${colors.brand.secondary};
  color: ${colors.text.light};
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 56px;
  display: none;
  ${respondTo.lg`
    display: flex;
  `}
`;
const ContentRow = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  &:not(:first-of-type) {
    border-bottom: 1px solid ${colors.border.dark};
  }
  &.has-hover-styles {
    :hover {
      text-decoration: none;
      background-color: ${colors.brand.tertiary};
      cursor: pointer;
    }
  }
`;
const ExpandedIndicator = styled.div`
  font-size: 1.75rem;
`;
const ContactLabel = styled.div`
  color: ${colors.text.accentlight};
`;
const ContactAddress = styled.div`
  color: ${colors.bluelink};
`;
const HeaderContainer = styled.div`
  max-width: 400px;
  ${respondTo.md`
    max-width: 500px;
  `}
  ${respondTo.xl`
    max-width: 100%;
    width: 100%;
  `}
`;

export const ContactIntro = ({ contactList }) => {
  const [isDropdownExpanded, setDropdownExpanded] = useState(false);
  const toggleDropdown = () => setDropdownExpanded(!isDropdownExpanded);
  return (
    <Background>
      <HeaderContainer>
        <Title>Let's Talk</Title>
        <SectionSubTextLight className="full-width">
          Our team is happy to answer your sales questions. Please fill out the
          form so we can personalize our conversation.
        </SectionSubTextLight>
      </HeaderContainer>
      <Dropdown onClick={toggleDropdown}>
        <ContentRow className="has-hover-styles">
          <div>For support contact Helpdesk</div>
          <ExpandedIndicator>
            {isDropdownExpanded ? '-' : `+`}
          </ExpandedIndicator>
        </ContentRow>
        {isDropdownExpanded &&
          contactList.map((contact) => (
            <ContentRow key={contact.label}>
              <ContactLabel>{contact.label}</ContactLabel>
              {contact.phoneNumber && (
                <ContactAddress>{contact.phoneNumber}</ContactAddress>
              )}
              {contact.emailAddress && (
                <ContactAddress>{contact.emailAddress}</ContactAddress>
              )}
            </ContentRow>
          ))}
      </Dropdown>
    </Background>
  );
};
