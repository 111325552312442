import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../../styles/variables';
import { GridBlock } from '@mq/generated-types/dist/GridBlock';
import { Squares } from '@mq/generated-types/dist/Squares';
import {
  SubComponentTextDark,
  SubComponentSubTextDark,
  BlueOutlineButton,
  IMG,
} from '../../styles/Library';
import PageComponent from '../PageComponent';
import { GridList } from '../GridList';
import CraftAsset from '../CraftAsset';

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-bottom: 8px;
`;
const CustomSubText = styled(SubComponentSubTextDark)`
  padding-bottom: 0 !important;
`;
const ButtonContainer = styled.div`
  padding-top: 32px;
`;
const Background = styled.div`
  background-color: ${({ theme }: { theme: string }) =>
    theme === 'grey' ? colors.offwhite : colors.white};
  background-color: 'pink';
`;
const ItemContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }: { theme: string }) =>
    theme === 'grey' ? colors.offwhite : colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface CraftData {
  craft: GridBlock;
}

const Grid: React.FC<any> = ({ craft }: any) => (
  <PageComponent
    backgroundColor={craft.theme === 'grey' ? colors.offwhite : colors.white}
  >
    <Background theme={craft.theme}>
      <GridList
        theme={craft.theme || ''}
        list={craft.squares || []}
        renderItem={(item: Squares) => (
          <ItemContent theme={craft.theme}>
            <div>
              {item.image && item.image[0] && item.image[0].url && (
                <div>
                  <IMG src={item.image[0].url || ''} />
                </div>
              )}
              {item.icon && item.icon[0] && (
                <IconContainer>
                  <CraftAsset asset={item.icon[0]} />
                </IconContainer>
              )}
              <SubComponentTextDark>{item.title}</SubComponentTextDark>
              <CustomSubText>{item.description}</CustomSubText>
            </div>
            {item.buttonLink && item.buttonLabel && (
              <ButtonContainer>
                <BlueOutlineButton to={item.buttonLink}>
                  {item.buttonLabel}
                </BlueOutlineButton>
              </ButtonContainer>
            )}
          </ItemContent>
        )}
      />
    </Background>
  </PageComponent>
);

export default Grid;
