import styled from '@emotion/styled';
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Clear,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Menu,
} from '@material-ui/icons';
import { Container, Library, styles } from '@mq/gatsby-components';

import { Analytics } from './Analytics';
import SubHeader from './NavMainSubHeader';
import { NavMainLoginSection } from './NavMainLoginSection';
import { MobileSubHeader } from './NavMainMobileSubHeader';

import {
  MarqueeNavigation,
  MarqueeNavigation_navigation,
  MarqueeNavigation_navigation_subNavigationMenu_Craft_navigationItems_navigationItems_Entry,
} from '@mq/generated-types/dist/MarqueeNavigation';

import { isQA, getMostRecentDraft, isCorrectAudience } from '../utils/misc';

const { heights, colors } = styles.variables;
const { respondTo } = styles.mixins;
const {
  CenteredFlex,
  NavigationButton,
  NavigationLink,
  NavigationExternalLink,
} = Library;

export const MarqueeNavigationFragment = graphql`
  fragment MarqueeNavigation on Craft_mainNavigation_mainNavigation_Entry {
    id
    title
    slug
    dateUpdated
    logo {
      ...Asset
    }

    navigation: mainNavigationItem {
      ... on Craft_mainNavigationItem_item_BlockType {
        id
        navigationLabel
        buttonLink
        position
        audience
        subNavigationMenu {
          title
          ... on Craft_navigationItems_navigationItems_Entry {
            id
            blockTitle
            buttonPrompt
            buttonLabel
            buttonLink
            subNavigationMenu {
              ... on Craft_subNavigationMenu_menuItem_BlockType {
                id
                icon {
                  ...Asset
                }
                blockTitle
                description
                subText
                href
              }
            }
          }
        }
      }
    }
  }
`;

const StyledHeader = styled.header`
  height: ${heights.header}px;
  background-color: ${colors.brand.primary};
  border-bottom: 1px solid ${colors.border.componentDark};
`;
const HeaderContainer = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  align-items: center;
  height: 100%;
  display: none;
  ${respondTo.xl`
    display: flex;
  `}
`;
const MobileHeaderContainer = styled(HeaderContainer)`
  display: flex;
  ${respondTo.xl`
    display: none;
  `}
`;

const NavHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
`;

const UtilityHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  &:last-child .utility-button {
    margin-left: 50px;
  }
`;
const GSLogoIMG = styled.img`
  max-width: 68px;
  margin-right: 32px;
  object-fit: contain;
`;
interface HomePageButtonI {
  isSelected: boolean;
}
const ToggledNavigationButton = styled(NavigationButton)`
  display: flex;
  flex-direction: row;
  min-width: 100px;
  height: 100%;
  background-color: ${(props: HomePageButtonI) =>
    props.isSelected ? colors.brand.secondary : 'transparent'};
  :focus {
    outline: 0;
  }
`;

const Hamburger = styled(CenteredFlex)`
  position: absolute;
  right: 0;
  color: ${colors.text.light};
  border-left: 1px solid ${colors.border.dark};
  height: 100%;
  width: 72px;
  &.open {
    background-color: ${colors.brand.secondary};
  }
`;

const CloseArea = styled.div`
  position: absolute;
  top: ${heights.header}px;
  left: 0;
  width: 100vw;
  height: 95vh;
  z-index: 3;
  &.hidden {
    display: none;
  }
`;

interface CraftData {
  craft: {
    nav: MarqueeNavigation;
    drafts: MarqueeNavigation[];
  };
}

const NavMain: React.FC = () => {
  const [selectedButton, setSelectedButton] = useState<string>('');
  const [isMobileSubVisible, setMobileSubVisible] = useState(false);
  const [selectedSubmenu, setSelectedSubmenu] = useState<
    MarqueeNavigation_navigation_subNavigationMenu_Craft_navigationItems_navigationItems_Entry[]
  >([]);
  function closeMenu() {
    setSelectedButton('');
    setSelectedSubmenu([]);
  }
  function toggleSelected(navigationLabel: string, subMenu: []) {
    if (navigationLabel === selectedButton) {
      closeMenu();
      return;
    }
    setSelectedButton(navigationLabel);
    setSelectedSubmenu(subMenu);
  }

  const { craft }: CraftData = useStaticQuery(graphql`
    query Navigation {
      craft {
        nav: entry(slug: "marquee-site-navigation") {
          ...MarqueeNavigation
        }
        drafts: entries(slug: "marquee-site-navigation", drafts: true) {
          ...MarqueeNavigation
        }
      }
    }
  `);

  const renderNavLinks = (
    navigation: (MarqueeNavigation_navigation | null)[] | null,
    pos: string,
  ) => {
    // TODO: update this `any`
    return navigation?.map(
      ({
        position,
        buttonLink,
        navigationLabel,
        subNavigationMenu,
        audience,
      }: any) => {
        if (position === pos) {
          if (!isCorrectAudience(audience)) return null;

          if (buttonLink) {
            return (
              <NavigationExternalLink
                key={navigationLabel}
                href={`${buttonLink}`}
              >
                {navigationLabel}
              </NavigationExternalLink>
            );
          }

          return (
            <ToggledNavigationButton
              key={navigationLabel}
              isSelected={selectedButton === navigationLabel}
              onClick={() => toggleSelected(navigationLabel, subNavigationMenu)}
            >
              {navigationLabel}
              {selectedButton === navigationLabel ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </ToggledNavigationButton>
          );
        }
      },
    );
  };

  let { nav, drafts } = craft;
  if (isQA && drafts.length) {
    nav = getMostRecentDraft(drafts);
  }

  return (
    <>
      <Analytics />
      <StyledHeader>
        <HeaderContainer>
          <NavHeader>
            <NavigationLink className="no-padding-left icon" to="/">
              {nav.logo?.map((img) =>
                img && img.url && img.id ? (
                  <GSLogoIMG
                    key={img.id}
                    src={img.url}
                    alt="goldman sachs logo"
                  />
                ) : null,
              )}
            </NavigationLink>
            {renderNavLinks(nav.navigation, 'left')}
          </NavHeader>
          <UtilityHeader>
            {renderNavLinks(nav.navigation, 'right')}
            <NavMainLoginSection />
          </UtilityHeader>
        </HeaderContainer>
        <MobileHeaderContainer>
          <NavigationLink className="no-padding-left icon" to="/">
            {nav.logo?.map((img) =>
              img && img.url && img.id ? (
                <GSLogoIMG
                  key={img.id}
                  src={img.url}
                  alt="goldman sachs logo"
                />
              ) : null,
            )}
          </NavigationLink>
          <NavMainLoginSection />
          <Hamburger
            className={isMobileSubVisible ? 'open' : 'closed'}
            onClick={() => setMobileSubVisible(!isMobileSubVisible)}
          >
            {isMobileSubVisible ? <Clear /> : <Menu />}
          </Hamburger>
        </MobileHeaderContainer>
        <SubHeader submenu={selectedSubmenu} />
        {nav.navigation?.length && (
          <>
            <CloseArea
              onClick={closeMenu}
              className={selectedSubmenu.length ? 'visible' : 'hidden'}
            />
            <MobileSubHeader
              isVisible={isMobileSubVisible}
              close={() => setMobileSubVisible(false)}
              navigation={nav.navigation}
            />
          </>
        )}
      </StyledHeader>
    </>
  );
};

export default NavMain;
