import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { ProfileContext } from '../components/ProfileContext';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { styles, Library } from '@mq/gatsby-components';

import { MarqueeNavigation_navigation } from '@mq/generated-types/dist/MarqueeNavigation';

const { heights, colors, widths } = styles.variables;
const { respondTo } = styles.mixins;
const { BlueButton, FlexRow, IconContainer, PrimaryOutlineLink } = Library;

interface ExpandedSubmenuProps {
  isVisible: boolean;
}
const ExpandedSubmenu = styled.div`
  top: ${heights.header}px;
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;
  z-index: 999;
  padding: 0;
  background-color: ${colors.brand.secondary};
  color: ${colors.text.light};
  width: 100%;
  max-width: ${widths.xxl}px;
  display: ${({ isVisible }: ExpandedSubmenuProps) =>
    isVisible ? 'inherit' : 'none'};
  ${respondTo.xl`
    display: none;
  `}
`;
const linkStyles = `
  color: ${colors.text.light};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  &:hover {
    text-decoration: none;
  }
`;
const StyledLink = styled(Link)`
  ${linkStyles}
`;
const StyledExternalLink = styled.a`
  ${linkStyles}
`;
const StyledButton = styled.div`
  color: ${colors.text.light};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  &:hover {
    text-decoration: none;
  }
`;
const SubMenuContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const MenuItem = styled(FlexRow)`
  height: 64px;
  width: 100%;
  padding: 21px 24px;
  justify-content: space-between;
`;
const SubMenuItem = styled(MenuItem)`
  background-color: ${colors.brand.tertiary};
  justify-content: flex-start;
  align-items: center;
`;
const SubMenuItemText = styled.div`
  padding-left: 8px;
`;
const MobileSecondaryRow = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${colors.brand.tertiary};
  ${respondTo.md`
    display: none;
  `}
`;
const ButtonRow = styled(FlexRow)`
  align-items: center;
  height: 100%;
`;
interface MobileHeaderProps {
  navigation: (MarqueeNavigation_navigation | null)[];
  isVisible: boolean;
  close: Function;
}
export const MobileSubHeader = ({
  close,
  isVisible,
  navigation,
}: MobileHeaderProps) => {
  const user = useContext(ProfileContext);
  const [openedSubIndex, setOpenedSubIndex] = useState<null | number>(null);
  const navItemHasSubItems = (navItem: MarqueeNavigation_navigation): boolean =>
    !!(navItem.subNavigationMenu && navItem.subNavigationMenu.length);

  const isLoggedIn = !!user;
  const isPublic = !user;
  const subMenuIsOpen = (index: number) => openedSubIndex === index;
  const renderButtonContent = (navItem, index) => (
    <>
      <MenuItem
        onClick={() => {
          navItemHasSubItems(navItem)
            ? openedSubIndex === null
              ? setOpenedSubIndex(index)
              : setOpenedSubIndex(null)
            : close();
        }}
      >
        {navItem.navigationLabel}
        <div>
          {navItemHasSubItems(navItem) ? (
            subMenuIsOpen(index) ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )
          ) : null}
        </div>
      </MenuItem>
      {navItem &&
        navItem.subNavigationMenu &&
        navItemHasSubItems(navItem) &&
        subMenuIsOpen(index) &&
        navItem?.subNavigationMenu[0]?.subNavigationMenu.map((subItem: any) => (
          <StyledLink key={subItem.href} to={subItem.href}>
            <SubMenuItem key={subItem.id}>
              {subItem.icon.length && (
                <IconContainer size="sm">
                  {subItem.icon.map((i) =>
                    i && i.id && i.url && i.title ? (
                      <img key={i.id} src={i.url} alt={i.title} />
                    ) : null,
                  )}
                </IconContainer>
              )}
              <SubMenuItemText>{subItem.blockTitle}</SubMenuItemText>
            </SubMenuItem>
          </StyledLink>
        ))}
    </>
  );
  return (
    <ExpandedSubmenu isVisible={isVisible}>
      <SubMenuContent>
        {navigation?.map(
          (navItem: MarqueeNavigation_navigation, index: number) =>
            navItem && navItemHasSubItems(navItem) ? (
              <StyledButton key={navItem.id || ''}>
                {renderButtonContent(navItem, index)}
              </StyledButton>
            ) : (
              <StyledExternalLink
                key={navItem.id || ''}
                href={navItem.buttonLink || ''}
              >
                {renderButtonContent(navItem, index)}
              </StyledExternalLink>
            ),
        )}
        {isLoggedIn && (
          <MobileSecondaryRow>
            <MenuItem>
              <ButtonRow>
                <PrimaryOutlineLink className="margin-left thin" href="/s">
                  Go To Marquee
                </PrimaryOutlineLink>
              </ButtonRow>
            </MenuItem>
          </MobileSecondaryRow>
        )}
        {isPublic && (
          <MobileSecondaryRow>
            <MenuItem>
              <ButtonRow>
                <BlueButton to={'/contact'} className="thin">
                  Contact Us
                </BlueButton>
                <PrimaryOutlineLink className="margin-left thin" href={'/l'}>
                  Log In
                </PrimaryOutlineLink>
              </ButtonRow>
            </MenuItem>
          </MobileSecondaryRow>
        )}
      </SubMenuContent>
    </ExpandedSubmenu>
  );
};
