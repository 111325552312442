import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Checkbox } from '@mq/aurora-components/dist/checkbox';

import { FlexRow } from '../styles/Library';
import { colors } from '../styles/variables';
import { FormStateI } from './ContactForm';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { FormInput } from './FormInput';
import { FormCheckbox } from './FormCheckbox';
import { generateContactEmail } from './utils';
import { respondTo } from '../styles/mixins';

const ButtonRow = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${respondTo.md`
    width: 500px;
  `}
  ${respondTo.xl`
    width: 100%;
  `}
`;
const InputContainer = styled.div`
  padding: 24px 0 12px 0;
`;
const ArrowButton = styled.button`
  background-color: ${colors.blue};
  color: ${colors.white};
  padding: 16px;
  border-radius: 3px;
  border: none;
  text-decoration: none;
  display: flex;
  font-size: 1rem;
  flex-direction: row;
  align-items: center;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  display: flex;
  flex-direction: row;
`;
const TransparentArrowButton = styled(ArrowButton)`
  background-color: ${colors.white};
  color: ${colors.text.dark};
  padding-left: 0;
`;
const ErrMsg = styled.div`
  background-color: ${colors.warning};
  color: ${colors.white};
  padding: 8px;
  margin-bottom: 8px;
`;
const TOSContainer = styled(FlexRow)`
  background-color: ${colors.offwhite};
  padding: 8px;
  margin-bottom: 24px;
  align-items: center;
  label {
    padding-left: 8px;
  }
`;
const TOSLink = styled.a`
  text-decoration: underline;
`;

interface ContactFormProps {
  updateFormProp: Function;
  updateList: Function;
  requiredFields: string[];
  formState: FormStateI;
  selectedPageHooks: [number, Function];
}

export const ContactFormPages = ({
  updateFormProp,
  updateList,
  requiredFields,
  formState,
  selectedPageHooks,
}: ContactFormProps) => {
  const [selectedPage, setSelectedPage] = selectedPageHooks;
  const [isTOSConfirmed, setTOSConfirmed] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const isFormCompleted = () => {
    setErrMessage('');
    if (!isTOSConfirmed) {
      setErrMessage('Accept TOS to continue');
      return false;
    }
    let isVerified = true;
    requiredFields.forEach((field) => {
      const fieldState = formState[field];
      if (!fieldState.length) {
        isVerified = false;
        setErrMessage('Fill all required fields');
      }
    });
    return isVerified;
  };
  const sendMail = () => (location.href = generateContactEmail(formState));
  return selectedPage == 1 ? (
    <FormContainer>
      <InputContainer>
        <FormInput
          title="Full name"
          type="text"
          value={formState.fullName}
          onChange={(e) => updateFormProp('fullName', e.target.value)}
          isRequired={requiredFields.includes('fullName')}
        />
        <FormInput
          title="Your company/organization"
          type="text"
          value={formState.company}
          onChange={(e) => updateFormProp('company', e.target.value)}
          isRequired={requiredFields.includes('company')}
        />
        <FormCheckbox
          title="Job functions"
          onChange={(e) => updateList('jobFunction', e.target.value)}
          options={[
            'Research Analyst',
            'Trader',
            'Quantitative Analyst',
            'Developer',
            'Portfolio Manager',
            'Operations',
            'Risk Manager',
            'Other',
          ]}
          selectedItems={formState.jobFunction}
          isRequired={requiredFields.includes('jobFunction')}
          showUserSubmission={formState.jobFunction.includes('Other')}
          updateUserSubmission={(e) =>
            updateFormProp('otherJobFunction', e.target.value)
          }
          userSubmissionValue={formState.otherJobFunction}
          userSubmissionPlaceholder="What is your job function?"
        />
        <FormInput
          title="Job title"
          type="text"
          value={formState.jobTitle}
          onChange={(e) => updateFormProp('jobTitle', e.target.value)}
          isRequired={requiredFields.includes('jobTitle')}
        />
      </InputContainer>
      <ButtonRow>
        <div />
        <ArrowButton onClick={() => setSelectedPage(2)}>
          Next
          <ArrowForward />
        </ArrowButton>
      </ButtonRow>
    </FormContainer>
  ) : (
    <FormContainer>
      <InputContainer>
        <FormCheckbox
          title="Markets that interest you"
          onChange={(e) => updateList('interestedMarkets', e.target.value)}
          options={[
            'Commodities',
            'Equities',
            'Credit',
            'Foreign Exchange',
            'Derivatives',
            'Rates',
          ]}
          selectedItems={formState.interestedMarkets}
          isRequired={requiredFields.includes('interestedMarkets')}
        />
        <FormCheckbox
          title="Offerings that interest you"
          onChange={(e) => updateList('interestedOfferings', e.target.value)}
          options={[
            'Research & Insights',
            'Index Solutions',
            'Data, Risk, & Analytics',
            'Prime',
            'Trade Execution',
            'Access Points',
          ]}
          selectedItems={formState.interestedOfferings}
          isRequired={requiredFields.includes('interestedOfferings')}
        />
        <FormInput
          title="Your Goldman Sachs contact"
          type="text"
          value={formState.gsContact}
          onChange={(e) => updateFormProp('gsContact', e.target.value)}
          isRequired={requiredFields.includes('gsContact')}
        />
        <FormInput
          title="Additional questions or comments"
          type="textarea"
          value={formState.additionalInfo}
          onChange={(e) => updateFormProp('additionalInfo', e.target.value)}
          isRequired={requiredFields.includes('additionalInfo')}
        />
      </InputContainer>
      <TOSContainer>
        <Checkbox
          checked={isTOSConfirmed}
          onChange={() => setTOSConfirmed(!isTOSConfirmed)}
        />
        <label>
          I consent to being contacted by Goldman Sachs to discuss products and
          services. For more information about how Goldman Sachs processes
          personal data, please refer to the{' '}
          <TOSLink
            target="_blank"
            href="https://www.goldmansachs.com/privacy-and-cookies/index.html"
          >
            Goldman Sachs' Privacy Policies
          </TOSLink>
        </label>
      </TOSContainer>
      {errMessage && <ErrMsg>{errMessage}</ErrMsg>}
      <ButtonRow>
        <TransparentArrowButton onClick={() => setSelectedPage(1)}>
          <ArrowBack />
          Previous
        </TransparentArrowButton>
        <div onClick={() => isFormCompleted() && sendMail()}>
          <ArrowButton>Submit</ArrowButton>
        </div>
      </ButtonRow>
    </FormContainer>
  );
};
